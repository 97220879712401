import * as React from 'react'
import router from './router'
import { RouterProvider } from 'react-router-dom'
import i18n from './i18n'

export default function App() {
  document.documentElement.lang = i18n.language

  return <RouterProvider router={router} />
}
