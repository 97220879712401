import { makeAutoObservable } from 'mobx'

export class Account {
  id = ''
  name = ''
  description = ''
  color = ''
  logo = ''

  constructor(account: Account | null = null) {
    account && Object.assign(this, account)
    makeAutoObservable(this)
  }

  setName(name: string) {
    this.name = name
  }

  setDescription(description: string) {
    this.description = description
  }

  setColor(color: string) {
    this.color = color
  }

  setLogo(logo: string) {
    if (logo === '') {
      this.logo = logo
    } else {
      this.logo = logo.split(',')[1]
    }
  }
}
export default Account
