import { makeAutoObservable } from 'mobx'

export class Project {
  id = ''
  name = ''
  description = ''

  constructor(project: Project | null = null) {
    project && Object.assign(this, project)
    makeAutoObservable(this)
  }

  setName(name: string) {
    this.name = name
  }

  setDescription(description: string) {
    this.description = description
  }
}
export default Project
