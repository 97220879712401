import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Button, Container } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import { LOGOUT_URL } from '../constants/urls';
import { useRootStore } from '../providers/RootStoreProvider'
import { ROLES } from '../constants/roles';

const Page403 = () => {
  const location = useLocation();
  const from = location.state?.from?.pathname || 'unknown page';
  const { authStore } = useRootStore();

  const hasValidRoles = authStore.loggedInUser?.roles?.some(role =>
    Object.values(ROLES as Record<string, string>).includes(role)
  ); const userEmail = authStore.loggedInUser?.email || 'Unknown';

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          textAlign: 'center',
        }}
      >
        <LockIcon sx={{ fontSize: 100, color: 'error.main', mb: 4 }} />
        <Typography variant="h2" component="h1" gutterBottom>
          {!hasValidRoles ? 'No Valid Roles' : 'Forbidden'}
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {!hasValidRoles
            ? "You don't have any valid roles assigned to your profile."
            : "You don't have permission to access this page."}
        </Typography>
        {!hasValidRoles && (
          <>
            <Typography variant="body1" gutterBottom>
              User: {userEmail}
            </Typography>
          </>
        )}
        <Typography variant="body1" gutterBottom>
          URL accessed: {from}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4 }}>
          <Button
            component={RouterLink}
            to="/"
            variant="contained"
            color="primary"
          >
            Go to Home
          </Button>
          <Button
            onClick={() => {
              window.location.href = LOGOUT_URL;
            }}
            variant="contained"
            color="primary"
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default observer(Page403);
