import React, { ReactNode, useEffect, useState } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import BorderAllTwoToneIcon from '@mui/icons-material/BorderAllTwoTone'

import '../../styles/DOMTemplateTreeView.css'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view'
import { getAllIdsDomResult } from './DesignerFunctions'

export default function DOMTreeView({
  data,
  onSelect,
  setPageNumber,
}: {
  data: any
  onSelect: (data: any) => void
  setPageNumber: (data: any) => void
}) {
  const [selectedDOMElement, setSelectedDOMElement] = useState<string>('')

  function traverseObject(jsonString: string, object: any) {
    const path = jsonString.split('-')
    let currentObject = object
    for (let i = 0; i < path.length; i++) {
      const pathElement = path[i]
      if (Object.prototype.hasOwnProperty.call(currentObject, pathElement)) {
        currentObject = currentObject[pathElement]
      } else {
        return null
      }
    }
    return currentObject
  }

  useEffect(() => {
    const selectedSpot = selectedDOMElement !== '' ? traverseObject(selectedDOMElement, { domRoot: data }) : false
    selectedSpot && onSelect(selectedSpot)
  }, [data])

  const DOMTreeItem = ({ obj, path }: { obj: any; path: any }) => {
    return (
      <>
        <TreeItem
          itemId={path}
          key={'children'}
          label={
            <TreeLabel
              id={path}
              icon={<BorderAllTwoToneIcon />}
              color={'#7aa27a'}
              label={
                <div className='DOMLabel'>
                  {'<' + (obj.data.type || obj.data.name) + '> ' || ''}
                  {obj.data.domTemplateObjectName || obj.data.text || ''}
                </div>
              }
              spot={obj}
            />
          }
        >
          {obj.children &&
            obj.children.map((childNode: any, childIndex: any) => (
              <DOMTreeItem
                obj={childNode}
                path={path + '-children-' + childIndex}
                key={path + '-children-' + childIndex}
              />
            ))}
        </TreeItem>
      </>
    )
  }

  const TreeLabel = ({
    icon,
    color,
    spot,
    label,
    id,
  }: {
    icon: ReactNode
    color: string
    spot?: any
    label: ReactNode
    id: string
  }) => {
    const handleSelectLabel = () => {
      spot.data.pageNumber > 0 && setPageNumber(spot.data.pageNumber)
      setSelectedDOMElement(id)
      spot && onSelect(spot)
    }

    return (
      <div
        className={'TreeLabel'}
        style={{ color }}
        onClick={(e) => {
          e.stopPropagation()
          handleSelectLabel()
        }}
      >
        {icon}
        <div>{label}</div>
      </div>
    )
  }
  return (
    <SimpleTreeView
      aria-label='customized'
      slots={{ collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon }}
      selectedItems={selectedDOMElement}
      defaultExpandedItems={getAllIdsDomResult(Array.isArray(data) ? data[0] : data, 'domRoot-0')}
    >
      {data && Array.isArray(data) ? (
        data.map((childData: any, childIndex: any) => (
          <DOMTreeItem obj={childData} path={'domRoot-' + childIndex} key={'domRoot-' + childIndex} />
        ))
      ) : (
        <DOMTreeItem obj={data} path={'domRoot'} />
      )}
    </SimpleTreeView>
  )
}
