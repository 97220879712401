import { makeAutoObservable, runInAction } from 'mobx'
import { RootStore } from './RootStore'
import AdHocPdf from '../domain/AdHocPdf'
import { createPdfBase64 } from '../api/EngineService'
import eventEmitter from '../components/eventEmitter'
import JSZip from 'jszip'

export class AdHocPdfStore {
  root: RootStore
  projectId = ''
  adHocPdfResults: AdHocPdf[] = []
  isLoading = false

  constructor(root: RootStore) {
    this.root = root
    makeAutoObservable(this)
  }

  add(adHocPdf: AdHocPdf) {
    runInAction(() => {
      this.adHocPdfResults.push(adHocPdf)
    })
  }

  remove(index: number) {
    runInAction(() => {
      this.adHocPdfResults = this.adHocPdfResults.filter((_, i) => i !== index)
    })
  }

  clear() {
    runInAction(() => {
      this.adHocPdfResults = this.adHocPdfResults.filter((a) => !a.checked)
    })
  }

  download(adHocPdf: AdHocPdf) {
    if (!adHocPdf.blob) {
      return
    }
    this.downloadPdf(adHocPdf.name, adHocPdf.blob)
  }

  checkAll(check: boolean) {
    this.adHocPdfResults.forEach((adHocPdf) => {
      adHocPdf.setCheck(check)
    })
  }

  async downloadChecked() {
    const zip = new JSZip()
    const folder = zip.folder('convertedPDFs')

    // Add PDFs to the folder
    this.adHocPdfResults.forEach((adHocPdf) => {
      if (adHocPdf.blob && adHocPdf.checked) {
        folder?.file(`${adHocPdf.name}.pdf`, adHocPdf.blob)
      }
    })

    // Generate the zipped content
    const content = await zip.generateAsync({ type: 'blob' })

    // Create a link to download the ZIP file
    const link = document.createElement('a')
    link.download = `${'convertedPDFs'}.zip`
    link.href = URL.createObjectURL(content)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  private downloadPdf(fileName: string, blob: Blob) {
    // const file = new File([content], fileName, { type: 'application/pdf' })
    const link = document.createElement('a')
    link.download = fileName
    link.href = URL.createObjectURL(blob)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  createPdf(name: string, pdfContent: string, templateId: string) {
    runInAction(() => {
      const pdf = new AdHocPdf()
      pdf.setTemplateId(templateId)
      pdf.setName(name.replace(/\.[^/.]+$/, ''))
      pdf.setStatus('Loading')
      pdf.setContent(pdfContent)
      this.add(pdf)
    })
  }

  async runEngineOn(accountId: string, projectId: string, templateId: string, index: number) {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      runInAction(() => {
        createPdfBase64(accountId, projectId, templateId, this.adHocPdfResults[index].content)
          .then((result) => {
            this.adHocPdfResults[index].setStatus('Converted')
            this.adHocPdfResults[index].setBlob(result)
          })
          .catch((error) => {
            this.adHocPdfResults[index].setStatus('Failed')
            const errorObject = JSON.parse(error.message)
            const innerMessageString = errorObject.message.split(' : ')[1]
            const cleanedInnerMessageString = innerMessageString.slice(1, -1)
            const innerMessageObject = JSON.parse(cleanedInnerMessageString)
            const desiredMessage = innerMessageObject.message.substring(0, 60)
            this.adHocPdfResults[index].setError(desiredMessage)
          })
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not run engine on - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  async runEngine(accountId: string, projectId: string, templateId: string, name: string, pdfContent: string) {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      runInAction(() => {
        createPdfBase64(accountId, projectId, templateId, pdfContent).then((result) => {
          const pdf = new AdHocPdf()
          pdf.setName(name.replace(/\.[^/.]+$/, '') + '_output.pdf')
          pdf.setBlob(result)
          this.add(pdf)
        })
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not run engine - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }
}
