import { toJS } from 'mobx'
import Account from '../domain/Account'
import User from '../domain/User'
import apiRequest from './ApiRequest'

const webApiUrl = '/bff/api/accounts'

class AccountService {
  list = async (): Promise<Account[]> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl, options)
    return apiRequest(request)
  }
  get = async (accountId: string): Promise<Account> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl + '/' + accountId, options)
    const response = await fetch(request)
    return await response.json()
  }
  post = async (account: Account): Promise<Account> => {
    const options: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(toJS(account)),
    }
    const request = new Request(webApiUrl, options)
    return await apiRequest(request)
  }
  put = async (account: Account): Promise<Account> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options: RequestInit = {
      method: 'PUT',
      headers,
      body: JSON.stringify(toJS(account)),
    }
    const request = new Request(webApiUrl + '/' + account.id, options)
    return await apiRequest(request)
  }
  delete = async (id: string): Promise<Response> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options = {
      method: 'DELETE',
      headers,
    }
    const request = new Request(webApiUrl + '/' + id, options)
    return await apiRequest(request)
  }

  listUsers = async (accountId: string): Promise<User[]> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl + '/' + accountId + '/users', options)
    return await apiRequest(request)
  }

  assignUser = async (accountId: string, userId: string): Promise<Response> => {
    const options: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl + '/' + accountId + '/users/' + userId, options)
    return await apiRequest(request)
  }

  removeUser = async (accountId: string, userId: string): Promise<Response> => {
    const options: RequestInit = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl + '/' + accountId + '/users/' + userId, options)
    return await apiRequest(request)
  }

  generateApiKey = async (accountId: string): Promise<string> => {
    const options: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl + '/' + accountId + '/generateApiKey', options)
    return await apiRequest(request).then((d) => {
      return d.apiKey
    })
  }
}

export default AccountService
