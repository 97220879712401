import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import { Button, Container, Modal, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRootStore } from '../providers/RootStoreProvider'
import { useNavigate, useParams } from 'react-router-dom'

interface AccountGenerateApiModal {
  generateApiKeyModalOpen: boolean
  setGenerateApiKeyModalOpen: (data: boolean) => void
  generateApiKey: () => Promise<void>
}

const GenerateApiKeyModal: React.FC<AccountGenerateApiModal> = ({
  generateApiKeyModalOpen,
  setGenerateApiKeyModalOpen,
  generateApiKey,
}) => {
  const { t } = useTranslation()
  const [generateApiKeySubmitted, setGenerateApiKeySubmitted] = useState<boolean>(false)

  const handleClose = useCallback(() => {
    setGenerateApiKeyModalOpen(false)
  }, [setGenerateApiKeyModalOpen])

  return (
    <Modal open={generateApiKeyModalOpen} onClose={handleClose}>
      <Container
        maxWidth='xs'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'var(--paper-color)',
          borderRadius: 1.5,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          padding: 3,
        }}
      >
        <Stack alignItems='center' textAlign='center'>
          <Typography variant='h6' gutterBottom>
            {t('account.generateApiKey.title')}
          </Typography>

          <Typography gutterBottom>{t('account.generateApiKey.text')}</Typography>
        </Stack>

        <Stack direction='row' justifyContent='center' spacing={2} mt={2}>
          <Button color='error' variant='contained' disabled={generateApiKeySubmitted} onClick={generateApiKey}>
            {t('account.generateApiKey.continue')}
          </Button>
          <Button variant='outlined' disabled={generateApiKeySubmitted} onClick={handleClose}>
            {t('account.generateApiKey.cancel')}
          </Button>
        </Stack>
      </Container>
    </Modal>
  )
}

export default observer(GenerateApiKeyModal)
