import { AccountStore } from './AccountStore'
import { ProjectStore } from './ProjectStore'
import { SampleDocumentStore } from './SampleDocumentStore'
import { AdHocPdfStore } from './AdHocPdfEngineStore'
import { TemplateStore } from './TemplateStore'
import { UserStore } from './UserStore'
import { TransactionStore } from './TransactionStore'
import { AuthStore } from './AuthStore'

export class RootStore {
  accountStore: AccountStore
  userStore: UserStore
  authStore: AuthStore
  projectStore: ProjectStore
  templateStore: TemplateStore
  sampleDocumentStore: SampleDocumentStore
  adHocPdfStore: AdHocPdfStore
  transactionStore: TransactionStore

  constructor() {
    this.accountStore = new AccountStore(this)
    this.userStore = new UserStore(this)
    this.authStore = new AuthStore(this)
    this.projectStore = new ProjectStore(this)
    this.templateStore = new TemplateStore(this)
    this.sampleDocumentStore = new SampleDocumentStore(this)
    this.adHocPdfStore = new AdHocPdfStore(this)
    this.transactionStore = new TransactionStore(this)
  }
}
