import React, { useEffect, useState } from 'react'
import { Button, Skeleton, Stack } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useRootStore } from '../../providers/RootStoreProvider'
import ArticleIcon from '@mui/icons-material/Article'
import { observer } from 'mobx-react'
import { Paper } from '../Paper'
import RowIElement from '../RowIElement'
import { useTranslation } from 'react-i18next'
import ProjectPropertiesModal from './ProjectPropertiesModal'

const ProjectManager = ({ editable, recentOnly }: { editable?: boolean; recentOnly?: boolean }) => {
  const { t } = useTranslation()

  const { accountId } = useParams()
  const navigate = useNavigate()
  const { accountStore, projectStore } = useRootStore()
  const [addProjectModalOpen, setAddProjectModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (accountId) {
      accountStore.loadCurrent(accountId)
      projectStore.setCurrentAccountId(accountId)
      projectStore.loadProjects()
      projectStore.clearCurrent()
    }
  }, [accountId])

  return (
    <>
      {editable && <h1> {t('projects.projectManager.projectManager')}</h1>}

      <>
        <Paper
          sx={{
            m: recentOnly ? '0px !important' : '0 auto',
          }}
          skeleton={projectStore.isLoading}
        >
          <Stack flexDirection={'row'} justifyContent={'space-between'} className='PaperHeader'>
            <h2> {recentOnly ? t('projects.projectManager.recent') : t('projects.projectManager.projects')}</h2>
            {editable && (
              <Button
                variant='contained'
                color='success'
                onClick={() => setAddProjectModalOpen(true)}
                disabled={projectStore.isLoading}
              >
                + {t('projects.projectManager.addProject')}
              </Button>
            )}
            {recentOnly && (
              <Button
                variant='contained'
                color='success'
                onClick={() => navigate('/accounts/' + accountId + '/projects/')}
                disabled={projectStore.isLoading}
              >
                + {t('projects.projectManager.viewAll')}
              </Button>
            )}
          </Stack>
          <Stack sx={{ overflow: 'auto' }} spacing={1}>
            {!projectStore.isLoading ? (
              projectStore.projects.slice(0, recentOnly ? 3 : 100).map((p, i) => (
                <RowIElement
                  key={'project-' + i}
                  onClick={() => {
                    navigate('/accounts/' + accountId + '/projects/' + p.id)
                  }}
                  title={p.name}
                  underTitle={p.description}
                  icon={<ArticleIcon />}
                />
              ))
            ) : (
              <>
                <Skeleton variant='rounded' sx={{ background: 'var(--light-gray-color)' }} width={'100%'} height={65} />
                <Skeleton variant='rounded' sx={{ background: 'var(--light-gray-color)' }} width={'100%'} height={65} />
              </>
            )}
          </Stack>
        </Paper>
        <ProjectPropertiesModal
          addProjectModalOpen={addProjectModalOpen}
          setAddProjectModalOpen={setAddProjectModalOpen}
        />
      </>
    </>
  )
}

export default observer(ProjectManager)
