export const structureElements = [
  'Document',
  'Part',
  'Art',
  'Sect',
  'Div',
  'BlockQuote',
  'Caption',
  'TOC',
  'TOCI',
  'Index',
  'NonStruct',
  'Private',
  // Block-Level Structure Elements
  'P',
  'H',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
  'L',
  'LI',
  'Lbl',
  'LBody',
  'Table',
  'TR',
  'TH',
  'TD',
  'THead',
  'TBody',
  'TFoot',
  // Inline-Level Structure Elements
  'Span',
  'Quote',
  'Note',
  'Reference',
  'BibEntry',
  'Code',
  'Link',
  'Annot',
  'Ruby',
  'RB',
  'RT',
  'RP',
  'Warichu',
  'WT',
  'WP',
  // Illustration Elements
  'Figure',
  'Formula',
  'Form',
]

export const structureElementsDocument = [
  'Art',
  'BlockQuote',
  'Caption',
  'Div',
  'Document',
  'Index',
  'NonStruct',
  'Part',
  'Private',
  'Sect',
  'TOC',
  'TOCI',
]
export const structureElementsTextField = ['H', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'L', 'LI', 'P']
export const structureElementsHeader = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6']

export const structureElementsTable = ['Lbl', 'LBody', 'Table', 'TR', 'TH', 'TD', 'THead', 'TBody', 'TFoot']
export const structureElementsInline = [
  'Annot',
  'BibEntry',
  'Code',
  'Link',
  'Note',
  'Quote',
  'RB',
  'Reference',
  'RP',
  'RT',
  'Ruby',
  'Span',
  'Warichu',
  'WP',
  'WT',
]
export const structureElementsIllustration = ['Art', 'Figure', 'Form', 'Formula']

export const searchTypes = ['contains', 'regex']
