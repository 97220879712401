import React, { useEffect, useState } from 'react'
import { Avatar, Button, Stack, TextField, IconButton, Typography, styled, Skeleton, Table } from '@mui/material'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { useRootStore } from '../providers/RootStoreProvider'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'

import { Paper } from '../components/Paper'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const User = () => {
  const { t, i18n } = useTranslation()
  const { userStore, authStore } = useRootStore()
  const navigate = useNavigate()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [profilePicture, setProfilePicture] = useState('')
  const [originalLanguage, setOriginalLanguage] = useState('en')
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (authStore.loggedInUser?.id) {
      userStore.loadCurrent(authStore.loggedInUser?.id).then(() => {
        if (userStore.currentUser) {
          setFirstName(userStore.currentUser.firstName || '')
          setLastName(userStore.currentUser.lastName || '')
          setJobTitle(userStore.currentUser.jobTitle || '')
          if (userStore.currentUser.profilePicture) {
            const imageTypeChar = userStore.currentUser.profilePicture.charAt(0)
            const imageType =
              imageTypeChar === '/' ? 'jpg' : imageTypeChar === 'i' ? 'png' : imageTypeChar === 'R' ? 'gif' : ''
            setProfilePicture(`data:image/${imageType};base64,${userStore.currentUser.profilePicture}`)
          }
          setOriginalLanguage(userStore.currentUser.language)
        }
      })
    }
  }, [authStore.loggedInUser?.id, userStore])

  const handleProfilePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setProfilePicture(reader.result as string)
      reader.onerror = (error) => console.error('Error converting file to Base64:', error)
    }
  }

  const handleClear = () => {
    setFirstName('')
    setLastName('')
    setJobTitle('')
    setProfilePicture('')
    i18n.changeLanguage(originalLanguage)
  }

  const handleSave = async () => {
    if (userStore.currentUser) {
      setSubmitted(true)

      userStore.currentUser.setFirstName(firstName)
      userStore.currentUser.setLastName(lastName)
      userStore.currentUser.setJobtitle(jobTitle)
      userStore.currentUser.setProfilePicture(profilePicture)
      i18n.resolvedLanguage && userStore.currentUser.setLanguage(i18n.resolvedLanguage)

      await userStore.updateCurrent().then(() => {
        navigate(-1)
        setSubmitted(false)
        handleClear()
      })
    }
  }

  const handleCancel = () => {
    navigate(-1)
    handleClear()
  }

  return (
    <Stack direction='column' height='inherit' alignItems='left'>
      <Stack direction={'row'} spacing={5} justifyContent={'center'}>
        <Paper>
          <Stack flexDirection='row' justifyContent='space-between' className='PaperHeader'>
            <h2>{t('users.userManager.userManager')}</h2>
          </Stack>
          <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 10px', 'td:last-child': { textAlign: 'end' } }}>
            <tbody>
              <tr>
                <td>
                  <Typography>{t('users.userManager.email')}</Typography>
                </td>
                <td>
                  {!userStore.isLoading ? (
                    <TextField
                      label={t('users.userManager.email')}
                      value={userStore.currentUser?.email}
                      disabled={true}
                      fullWidth
                    />
                  ) : (
                    <Skeleton height={100} sx={{ margin: '-22px 0' }} />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>{t('users.userManager.firstName')}</Typography>
                </td>
                <td>
                  {!userStore.isLoading ? (
                    <TextField
                      label={t('users.userManager.firstName')}
                      value={firstName}
                      disabled={submitted || userStore.isLoading}
                      onChange={(e) => setFirstName(e.target.value)}
                      fullWidth
                    />
                  ) : (
                    <Skeleton height={100} sx={{ margin: '-22px 0' }} />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>{t('users.userManager.lastName')}</Typography>
                </td>
                <td>
                  {!userStore.isLoading ? (
                    <TextField
                      label={t('users.userManager.lastName')}
                      multiline
                      value={lastName}
                      disabled={submitted}
                      onChange={(e) => setLastName(e.target.value)}
                      fullWidth
                    />
                  ) : (
                    <Skeleton height={100} sx={{ margin: '-22px 0' }} />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>{t('users.userManager.jobTitle')}</Typography>
                </td>
                <td>
                  {!userStore.isLoading ? (
                    <TextField
                      label={t('users.userManager.jobTitle')}
                      multiline
                      value={jobTitle}
                      disabled={submitted}
                      onChange={(e) => setJobTitle(e.target.value)}
                      fullWidth
                    />
                  ) : (
                    <Skeleton height={100} sx={{ margin: '-22px 0' }} />
                  )}
                </td>
              </tr>

              <tr>
                <td>
                  <Typography>{t('users.userManager.profilePicture')}</Typography>
                </td>
                <td>
                  <div style={{ position: 'relative', display: 'flex', justifyContent: 'end' }}>
                    <VisuallyHiddenInput
                      type='file'
                      accept='image/*'
                      onChange={handleProfilePictureChange}
                      id='icon-button-file'
                      disabled={submitted || userStore.isLoading}
                    />
                    <label htmlFor='icon-button-file'>
                      <IconButton color='primary' aria-label='upload picture' component='span'>
                        <Avatar sx={{ width: 56, height: 56 }} src={profilePicture} />
                      </IconButton>
                    </label>
                    {profilePicture && (
                      <IconButton
                        onClick={() => setProfilePicture('')}
                        style={{
                          position: 'absolute',
                          top: 15,
                          right: 15,
                          transform: 'translate(50%, -50%)',
                          background: '#f7f7f7',
                          borderRadius: '50%',
                          padding: '2px',
                        }}
                        size='small'
                        color='primary'
                      >
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>{t('users.userManager.language')}</Typography>
                </td>
                <td>
                  <Stack direction='row' spacing={1} justifyContent='end'>
                    {['no', 'en'].map((lang) => (
                      <Button
                        key={lang}
                        color={i18n.resolvedLanguage === lang ? 'success' : 'inherit'}
                        variant='contained'
                        type='submit'
                        disabled={submitted || userStore.isLoading}
                        onClick={() => i18n.changeLanguage(lang)}
                      >
                        {lang === 'no' ? 'Norsk' : 'English'}
                      </Button>
                    ))}
                  </Stack>
                </td>
              </tr>
            </tbody>
          </Table>
          <Stack direction='row' spacing={1} justifyContent='end' mt={5}>
            <Button
              color='secondary'
              variant='contained'
              disabled={submitted || userStore.isLoading}
              onClick={handleSave}
            >
              {t('users.userProperties.save')}
            </Button>
            <Button variant='outlined' disabled={submitted || userStore.isLoading} onClick={handleCancel}>
              {t('users.userProperties.cancel')}
            </Button>
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  )
}

export default observer(User)
