import React, { useState, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Button, Container, MenuItem, Modal, Select, Stack, Typography } from '@mui/material'
import { snippets } from '../../domain/SnippetObjects'
import { useTranslation } from 'react-i18next'

interface SnippetModalProps {
  snippetFunction: { function: (data: any) => void } | null
  setSnippetFunction: (data: any) => void
}

const SnippetModal: React.FC<SnippetModalProps> = ({ snippetFunction, setSnippetFunction }) => {
  const { t } = useTranslation()

  const [snippetModal, setSnippetModal] = useState<boolean>(false)
  const [snippet, setSnippet] = useState<number>(0)

  useEffect(() => {
    if (snippetFunction != null) {
      setSnippetModal(true)
    } else {
      setSnippetModal(false)
    }
  }, [snippetFunction])

  const handleClose = useCallback(() => {
    setSnippetModal(false)
    setSnippetFunction(null)
  }, [setSnippetModal, setSnippetFunction])

  return (
    <Modal open={snippetModal} onClose={handleClose}>
      <Container
        maxWidth='xs'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'var(--paper-color)',
          borderRadius: 1.5,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          padding: 3,
        }}
      >
        <Stack alignItems='center' textAlign='center'>
          <Typography variant='h6' gutterBottom>
            {t('designer.templateTreeView.snippetModal.selectSnippet')}
          </Typography>
          <Select value={snippet} onChange={(e) => setSnippet(Number(e.target.value))}>
            {snippets.map((item, index) => (
              <MenuItem key={index} value={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>

        <Stack direction='row' justifyContent='center' spacing={2} mt={2}>
          <Button
            color='success'
            variant='contained'
            onClick={() => {
              if (snippetFunction != null) {
                snippetFunction.function(snippets[snippet])
              }
              handleClose()
            }}
          >
            {t('designer.templateTreeView.snippetModal.add')}
          </Button>
          <Button variant='outlined' onClick={handleClose}>
            {t('designer.templateTreeView.snippetModal.cancel')}
          </Button>
        </Stack>
      </Container>
    </Modal>
  )
}

export default observer(SnippetModal)
