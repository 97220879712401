import React, { useState, useCallback, useEffect } from 'react'
import { Button, Container, Modal, Stack, TextField, Typography, Select, MenuItem } from '@mui/material'
import { useRootStore } from '../../providers/RootStoreProvider'
import { observer } from 'mobx-react-lite'
import User from '../../domain/User'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const UserPropertiesModal = ({
  giveAccessModalOpen,
  setGiveAccessModalOpen,
}: {
  giveAccessModalOpen: boolean
  setGiveAccessModalOpen: (data: boolean) => void
}) => {
  const { t } = useTranslation()
  const { accountId } = useParams()
  const { accountStore, userStore } = useRootStore()
  const [selectedUser, setSelectedUser] = useState<string>('')

  const [submitted, setSubmitted] = useState<boolean>(false)

  useEffect(() => {
    if (accountId) {
      userStore.loadUsers()
    }
  }, [accountId])

  const resetForm = useCallback(() => {
    console.log('reset')
  }, [])

  const handleSave = useCallback(() => {
    setSubmitted(true)
    console.log(selectedUser)
    accountStore.assignUser(selectedUser).then((e) => {
      console.log(e)
      resetForm()
      accountStore.loadUsers()
      setSubmitted(false)
      setGiveAccessModalOpen(false)
    })
  }, [resetForm, setGiveAccessModalOpen, selectedUser])

  return (
    <Modal
      open={giveAccessModalOpen}
      onClose={() => {
        setGiveAccessModalOpen(false)
      }}
    >
      <Container
        maxWidth='xs'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'var(--paper-color)',
          borderRadius: 1.5,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          padding: 3,
        }}
      >
        <Typography variant='h6' component='h2' mb={2}>
          {t('users.userProperties.giveAccess')}
        </Typography>

        <Stack spacing={2}></Stack>
        <Select fullWidth value={selectedUser} onChange={(event) => setSelectedUser(event.target.value)}>
          {userStore.users.map((user, index) => (
            <MenuItem key={index} value={user.id}>
              {user.email}
            </MenuItem>
          ))}
        </Select>
        <Stack direction='row' justifyContent='flex-end' spacing={2} mt={2}>
          <Button
            color='secondary'
            variant='contained'
            disabled={submitted || selectedUser === ''}
            onClick={handleSave}
          >
            {t('users.userProperties.give')}
          </Button>
          <Button variant='outlined' disabled={submitted} onClick={() => setGiveAccessModalOpen(false)}>
            {t('users.userProperties.cancel')}
          </Button>
        </Stack>
      </Container>
    </Modal>
  )
}

export default observer(UserPropertiesModal)
