import { toJS } from 'mobx'
import User from '../domain/User'
import apiRequest from './ApiRequest'

const webApiUrl = '/bff/api/users'

class UserService {
  list = async (): Promise<User[]> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl, options)
    return await apiRequest(request)
  }
  me = async (): Promise<User> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl + '/me', options)
    return await apiRequest(request)
  }
  get = async (userId: string): Promise<User> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl + '/' + userId, options)
    return await apiRequest(request)
  }
  post = async (user: User): Promise<User> => {
    const options: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(toJS(user)),
    }
    const request = new Request(webApiUrl, options)
    return await apiRequest(request)
  }
  put = async (user: User): Promise<User> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options: RequestInit = {
      method: 'PUT',
      headers,
      body: JSON.stringify(toJS(user)),
    }
    const request = new Request(webApiUrl + '/' + user.id, options)
    return await apiRequest(request)
  }

  delete = async (id: string): Promise<Response> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options = {
      method: 'DELETE',
      headers,
    }
    const request = new Request(webApiUrl + '/' + id, options)
    return await apiRequest(request)
  }
}

export default UserService
