import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { RootStore } from './RootStore'
import ProjectService from '../api/ProjectService'
import Project from '../domain/Project'
import eventEmitter from '../components/eventEmitter'

export class ProjectStore {
  root: RootStore
  projectService: ProjectService
  currentAccountId = ''
  currentProject: Project | null = null
  projects: Project[] = []
  isLoading = true

  constructor(root: RootStore) {
    this.root = root
    this.projectService = new ProjectService()
    makeAutoObservable(this)
  }

  setCurrentAccountId(accountId: string) {
    this.currentAccountId = accountId
  }

  async loadProjects() {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const projects = await this.projectService.list(this.currentAccountId)
      runInAction(() => {
        this.projects = projects.map((t) => new Project(t))
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load projects - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  async loadCurrent(projectId: string) {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const project = await this.projectService.get(this.currentAccountId, projectId)
      runInAction(() => {
        this.currentProject = new Project(project)
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load current project - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  clearCurrent() {
    this.currentProject = null
  }

  async updateCurrent() {
    if (!this.currentProject) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update project - No project selected',
        severity: 'error',
      })
      return
    }
    try {
      await this.projectService.put(this.currentAccountId, this.currentProject)

      const projectIndex = this.projects.findIndex((t) => t.id === this.currentProject?.id)
      runInAction(() => {
        if (projectIndex != -1 && this.currentProject) {
          this.projects[projectIndex] = this.currentProject
        }
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update project - ' + error,
        severity: 'error',
      })
    }
  }

  async delete(project: Project) {
    try {
      await this.projectService.delete(this.currentAccountId, project.id)
      runInAction(() => {
        this.projects = this.projects.filter((t) => t.id !== project.id)
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not delete project - ' + error,
        severity: 'error',
      })
    }
  }

  async create(project: Project): Promise<Project> {
    try {
      const newProject: Project = await this.projectService.post(this.currentAccountId, project)
      runInAction(() => {
        this.projects.push(newProject)
      })
      return newProject
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not create project - ' + error,
        severity: 'error',
      })
      return Promise.reject(error)
    }
  }
}
