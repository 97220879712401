import { toJS } from 'mobx'
import SampleDocument from '../domain/SampleDocument'
import apiRequest from './ApiRequest'

const webApiUrl = '/bff/api/accounts/'

class SampleDocumentService {
  list = async (accountId: string, projectId: string, templateId: string): Promise<SampleDocument[]> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(
      webApiUrl + accountId + '/projects/' + projectId + '/templates/' + templateId + '/sampleDocuments',
      options
    )
    const response = await fetch(request)
    return await response.json()
  }
  get = async (
    accountId: string,
    projectId: string,
    templateId: string,
    sampleDocumentId: string
  ): Promise<SampleDocument> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(
      webApiUrl +
        accountId +
        '/projects/' +
        projectId +
        '/templates/' +
        templateId +
        '/sampleDocuments/' +
        sampleDocumentId,
      options
    )
    const response = await fetch(request)
    return await response.json()
  }
  post = async (
    accountId: string,
    projectId: string,
    templateId: string,
    sampleDocument: SampleDocument
  ): Promise<SampleDocument> => {
    const options: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(sampleDocument),
    }
    const request = new Request(
      webApiUrl + accountId + '/projects/' + projectId + '/templates/' + templateId + '/sampleDocuments',
      options
    )
    return await apiRequest(request)
  }
  put = async (
    accountId: string,
    projectId: string,
    templateId: string,
    sampleDocument: SampleDocument
  ): Promise<SampleDocument> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options: RequestInit = {
      method: 'PUT',
      headers,
      body: JSON.stringify(toJS(sampleDocument)),
    }
    const request = new Request(
      webApiUrl +
        accountId +
        '/projects/' +
        projectId +
        '/templates/' +
        templateId +
        '/sampleDocuments/' +
        sampleDocument.id,
      options
    )
    return await apiRequest(request)
  }
  delete = async (
    accountId: string,
    projectId: string,
    templateId: string,
    sampleDocumentId: string
  ): Promise<Response> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options = {
      method: 'DELETE',
      headers,
    }
    const request = new Request(
      webApiUrl +
        accountId +
        '/projects/' +
        projectId +
        '/templates/' +
        templateId +
        '/sampleDocuments/' +
        sampleDocumentId,
      options
    )
    const response = await fetch(request)
    return response
  }
  createPdf = async (
    accountId: string,
    projectId: string,
    templateId: string,
    sampleDocumentId: string
  ): Promise<Blob> => {
    const options: RequestInit = {
      method: 'GET',
    }
    const request = new Request(
      webApiUrl +
        accountId +
        '/projects/' +
        projectId +
        '/templates/' +
        templateId +
        '/sampleDocuments/' +
        sampleDocumentId +
        '/createPdf',
      options
    )
    return await apiRequest(request, 'blob')
  }
  parseSampleDocument = async (
    accountId: string,
    projectId: string,
    templateId: string,
    sampleDocumentId: string
  ): Promise<any> => {
    const options: RequestInit = {
      method: 'GET',
    }
    const request = new Request(
      webApiUrl +
        accountId +
        '/projects/' +
        projectId +
        '/templates/' +
        templateId +
        '/sampleDocuments/' +
        sampleDocumentId +
        '/parse',
      options
    )
    return await apiRequest(request)
  }
}

export default SampleDocumentService
