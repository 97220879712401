/**
 * browser.ts
 *
 * This module sets up the Mock Service Worker (MSW) for browser environments.
 *
 * Usage:
 * This worker is typically used in conjunction with a setup file to initialize
 * MSW for local development and testing.
 */

import { setupWorker } from 'msw/browser'
import { handlers } from './handlers'

export const worker = setupWorker(...handlers)
