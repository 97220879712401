import React from 'react'
import { useRouteError, isRouteErrorResponse, Link } from "react-router-dom";
import { Box, Typography, Button, Container } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function ErrorPage() {
  const error = useRouteError();
  let errorMessage: string;
  const currentUrl = window.location.href; // Get the current URL
  const referrerUrl = document.referrer; // Get the referrer URL

  if (isRouteErrorResponse(error)) {
    errorMessage = error.data.message || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = 'Unknown error';
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          textAlign: 'center',
        }}
      >
        <ErrorOutlineIcon sx={{ fontSize: 100, color: 'error.main', mb: 4 }} />
        <Typography variant="h2" component="h1" gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Sorry, the page you are looking for does not exist.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <i>{errorMessage}</i>
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          URL Accessed: {currentUrl}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Referrer URL: {referrerUrl || 'No referrer available'}
        </Typography>
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
        >
          Go to Home
        </Button>
      </Box>
    </Container>
  );
}
