import { toJS } from 'mobx'
import Template from '../domain/Template'
import apiRequest from './ApiRequest'

const webApiUrl = '/bff/api/accounts/'

class TemplateService {
  list = async (accountId: string, projectId: string): Promise<Template[]> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl + accountId + '/projects/' + projectId + '/templates', options)
    return await apiRequest(request)
  }
  get = async (accountId: string, projectId: string, templateId: string): Promise<Template> => {
    const options: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const request = new Request(webApiUrl + accountId + '/projects/' + projectId + '/templates/' + templateId, options)
    return await apiRequest(request)
  }
  post = async (accountId: string, projectId: string, template: Template): Promise<Template> => {
    const options: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(toJS(template)),
    }
    const request = new Request(webApiUrl + accountId + '/projects/' + projectId + '/templates', options)
    return await apiRequest(request)
  }
  put = async (accountId: string, projectId: string, template: Template): Promise<Template> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options: RequestInit = {
      method: 'PUT',
      headers,
      body: JSON.stringify(toJS(template)),
    }
    const request = new Request(webApiUrl + accountId + '/projects/' + projectId + '/templates/' + template.id, options)
    return await apiRequest(request)
  }
  updateTemplateAndDomResult = async (
    accountId: string,
    projectId: string,
    sampleDocumentId: string,
    template: Template
  ): Promise<any> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options: RequestInit = {
      method: 'PUT',
      headers,
      body: JSON.stringify(toJS(template)),
    }
    const request = new Request(
      webApiUrl +
        accountId +
        '/projects/' +
        projectId +
        '/templates/' +
        template.id +
        '/sampleDocuments/' +
        sampleDocumentId +
        '/domResult',
      options
    )
    return await apiRequest(request)
  }
  delete = async (accountId: string, projectId: string, templateId: string): Promise<Response> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const options = {
      method: 'DELETE',
      headers,
    }
    const request = new Request(webApiUrl + accountId + '/projects/' + projectId + '/templates/' + templateId, options)
    return await apiRequest(request)
  }
}

export default TemplateService
