import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  Skeleton,
  Stack,
} from '@mui/material'
import Drop from '../components/Drop'

import { useRootStore } from '../providers/RootStoreProvider'
import { observer } from 'mobx-react'
import AdHocPdf from '../domain/AdHocPdf'
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone'
import ClearIcon from '@mui/icons-material/Clear'
import { CheckBox } from '@mui/icons-material'
import { Paper } from './Paper'
import RowIElement from './RowIElement'
import { useTranslation } from 'react-i18next'

type Props = {
  templateId: string | undefined
}

const AdHocPdfEngine = ({ templateId }: Props) => {
  const { t } = useTranslation()

  const { adHocPdfStore, accountStore, projectStore, templateStore } = useRootStore()

  const [checkAll, setCheckAll] = useState(true)

  async function onSelectedFiles(files: File[]): Promise<void> {
    if (!templateStore.currentTemplate) {
      return
    }

    // Convert files to an array of Promises that resolve after the file reading is done
    const filePromises: Promise<void>[] = files.map((file) => {
      return new Promise<void>((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          const base64Data = reader.result as string
          templateStore.currentTemplate &&
            adHocPdfStore.createPdf(file.name, base64Data.split(',')[1], templateStore.currentTemplate.id)
          resolve() // Ensure resolve() is called with void
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    })

    try {
      await Promise.all(filePromises)

      for (let index = 0; index < adHocPdfStore.adHocPdfResults.length; index++) {
        if (adHocPdfStore.adHocPdfResults[index].status === 'Loading') {
          await adHocPdfStore.runEngineOn(
            templateStore.currentAccountId,
            templateStore.currentProjectId,
            templateStore.currentTemplate.id,
            index
          )
        }
      }
    } catch (error) {
      console.error('Error processing files:', error)
    }
  }

  const skeleton =
    !templateStore ||
    templateStore.templates === null ||
    !projectStore.currentProject ||
    !accountStore.currentAccount ||
    adHocPdfStore.isLoading

  return (
    <Paper skeleton={skeleton}>
      <Stack className='PaperHeader'>
        <h2>{t('sampleDocuments.adHoc.convert')}</h2>
        <h3>{t('sampleDocuments.adHoc.upload')}</h3>
      </Stack>
      <Drop onSelectedFiles={onSelectedFiles} disabled={skeleton} />

      <Stack sx={{ alignItems: 'left', m: 2, mt: 2, overflow: 'auto' }} spacing={1}>
        {!skeleton ? (
          adHocPdfStore.adHocPdfResults
            .filter((value) => value.templateId === templateStore.currentTemplate?.id)
            .map((adHocPdf: AdHocPdf, index: number) => (
              <RowIElement
                key={'adHocPdf-' + index}
                onClick={() => {
                  adHocPdfStore.download(adHocPdf)
                }}
                onRemove={(e) => {
                  e.stopPropagation()
                  adHocPdfStore.remove(index)
                }}
                checked={adHocPdf.checked}
                onCheck={(e) => {
                  e.stopPropagation()
                  adHocPdf.toggleCheck()
                  setCheckAll(false)
                }}
                title={adHocPdf.name}
                underTitle={
                  (adHocPdf.status === 'Converted'
                    ? t('sampleDocuments.adHoc.status.converted')
                    : adHocPdf.status === 'Loading'
                      ? t('sampleDocuments.adHoc.status.loading')
                      : adHocPdf.status === 'Failed' && t('sampleDocuments.adHoc.status.failed')) +
                  (adHocPdf.error != '' ? ' - ' + adHocPdf.error + '...' : ' ')
                }
                icon={
                  <Box sx={{ position: 'relative' }}>
                    {adHocPdf.status === 'Converted' ? (
                      <Box
                        sx={{
                          fontWeight: 600,
                          position: 'absolute',
                          fontSize: 9,
                          background: '#c1cad0',
                          width: 'fit-content',
                          height: 'fit-content',
                          lineHeight: '9px',
                          borderRadius: '2px',
                          border: 'solid var(--secondary-color) 2px',
                          p: '0px 1px',
                          mt: 1.5,
                        }}
                      >
                        {t('sampleDocuments.adHoc.ua')}
                      </Box>
                    ) : adHocPdf.status === 'Loading' ? (
                      <CircularProgress
                        size={20}
                        color='inherit'
                        sx={{
                          mt: 1.5,
                          position: 'absolute',
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    <InsertDriveFileTwoToneIcon />
                  </Box>
                }
              />
            ))
        ) : (
          <Skeleton variant='rounded' sx={{ background: 'var(--light-gray-color)' }} width={'100%'} height={75} />
        )}
      </Stack>
      <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'end'} position={'relative'} mt='auto'>
        <Box left={25} position={'absolute'}>
          <FormGroup>
            <FormControlLabel
              label={t('elementRow.checkAll')}
              control={
                <Checkbox
                  checked={checkAll && !(adHocPdfStore.adHocPdfResults.length === 0)}
                  onChange={() => {
                    setCheckAll(!checkAll)
                    adHocPdfStore.checkAll(!checkAll)
                  }}
                  sx={{
                    color: 'var(--secondary-color)',
                    '&.Mui-checked': {
                      color: 'var(--secondary-color)',
                    },
                  }}
                  disabled={adHocPdfStore.adHocPdfResults.length === 0}
                />
              }
            />
          </FormGroup>
        </Box>
        <Button
          variant='contained'
          color='success'
          disabled={adHocPdfStore.adHocPdfResults.filter((a, i) => a.checked).length == 0}
          onClick={() => adHocPdfStore.downloadChecked()}
        >
          {t('sampleDocuments.adHoc.download')}
        </Button>
        <Button
          variant='contained'
          color='inherit'
          disabled={adHocPdfStore.adHocPdfResults.filter((a, i) => a.checked).length == 0}
          onClick={() => adHocPdfStore.clear()}
        >
          {t('sampleDocuments.adHoc.clear')}
        </Button>
      </Stack>
    </Paper>
  )
}

export default observer(AdHocPdfEngine)
