/**
 * handlers.ts
 *
 * This module defines mock request handlers for the Mock Service Worker (MSW).
 *
 * Purpose:
 * Provides mock responses for specific API endpoints during development and testing.
 */

import { http, HttpResponse } from 'msw'
import { adminUser } from './mockUsers'
import { IUser } from '../domain/User'
let currentUser = adminUser

export const setMockUser = (user: IUser) => {
  currentUser = user
}

export const handlers = [
  http.get('/bff/api/users/me', async () => {
    // Simulate a delay
    await new Promise((resolve) => setTimeout(resolve, 500))
    return HttpResponse.json(currentUser)
  }),
]
