import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { RootStore } from './RootStore'
import TemplateService from '../api/TemplateService'
import Template from '../domain/Template'
import eventEmitter from '../components/eventEmitter'

export class TemplateStore {
  root: RootStore
  templateService: TemplateService
  currentAccountId = ''
  currentProjectId = ''
  currentTemplate: Template | null = null
  templates: Template[] = []
  isLoading = true

  constructor(root: RootStore) {
    this.root = root
    this.templateService = new TemplateService()
    makeAutoObservable(this)
  }

  setCurrentAccountId(accountId: string) {
    this.currentAccountId = accountId
  }

  setCurrentProjectId(projectId: string) {
    this.currentProjectId = projectId
  }

  async loadTemplates() {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const templates = await this.templateService.list(this.currentAccountId, this.currentProjectId)
      runInAction(() => {
        this.templates = templates.map((t) => new Template(t))
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load templates - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  async loadCurrent(templateId: string) {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const template = await this.templateService.get(this.currentAccountId, this.currentProjectId, templateId)
      runInAction(() => {
        this.currentTemplate = new Template(template)
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load current - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  clearCurrent() {
    this.currentTemplate = null
  }

  async updateCurrent() {
    if (!this.currentTemplate) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update current - No template selected ',
        severity: 'error',
      })
      return
    }
    try {
      await this.templateService.put(this.currentAccountId, this.currentProjectId, this.currentTemplate)
      const templateIndex = this.templates.findIndex((t) => t.id === this.currentTemplate?.id)
      runInAction(() => {
        if (templateIndex != -1 && this.currentTemplate) {
          this.templates[templateIndex] = this.currentTemplate
        }
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update current - ' + error,
        severity: 'error',
      })
    }
  }

  async delete(template: Template) {
    try {
      await this.templateService.delete(this.currentAccountId, this.currentProjectId, template.id)
      runInAction(() => {
        this.templates = this.templates.filter((t) => t.id !== template.id)
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not delete template - ' + error,
        severity: 'error',
      })
    }
  }

  async create(template: Template): Promise<Template> {
    try {
      const newTemplate: Template = await this.templateService.post(
        this.currentAccountId,
        this.currentProjectId,
        template
      )
      runInAction(() => {
        this.templates.push(newTemplate)
      })
      return newTemplate
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not create template - ' + error,
        severity: 'error',
      })
      return Promise.reject(error)
    }
  }

  setCurrentTemplate(template: Template) {
    if (!this.currentTemplate) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not set current ',
        severity: 'error',
      })
      return
    }
    runInAction(() => {
      this.currentTemplate = template
    })
  }

  async updateCurrentTemplateAndReturnDomResult(sampleDocumentId: string): Promise<any> {
    if (!this.currentTemplate) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update current - No template selected ',
        severity: 'error',
      })
      return
    }
    try {
      const result: any = await this.templateService.updateTemplateAndDomResult(
        this.currentAccountId,
        this.currentProjectId,
        sampleDocumentId,
        this.currentTemplate
      )
      const templateIndex = this.templates.findIndex((t) => t.id === this.currentTemplate?.id)
      runInAction(() => {
        if (templateIndex != -1 && this.currentTemplate) {
          this.templates[templateIndex] = this.currentTemplate
        }
      })
      return result
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update current and produce results - ' + error,
        severity: 'error',
      })
      return Promise.reject(error)
    }
  }
}
