import React, { useEffect, useState } from 'react'
import { Button, Skeleton, Stack, Tooltip } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useRootStore } from '../providers/RootStoreProvider'
import { observer } from 'mobx-react'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import TemplateManager from '../components/Template/TemplateManager'
import ProjectPropertiesModal from '../components/Project/ProjectPropertiesModal'
import ProjectDeleteModal from '../components/Project/ProjectDeleteModal'
import { useTranslation } from 'react-i18next'

const Project = () => {
  const { t } = useTranslation()

  const { accountId, projectId } = useParams()
  const { projectStore, templateStore } = useRootStore()
  const [editProjectModalOpen, setEditProjectModalOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (accountId && projectId) {
      projectStore.setCurrentAccountId(accountId)
      projectStore.loadCurrent(projectId)
      templateStore.clearCurrent()
    }
  }, [accountId, projectId])

  return (
    <>
      {projectStore.currentProject ? (
        <Stack direction={'row'} alignItems='center' justifyContent={'between'}>
          <h1>{projectStore.currentProject.name}</h1>

          <Stack
            direction={'row'}
            sx={{
              ml: 'auto',
              '.MuiButton-root': {
                width: '40px',
                height: '30px',
                minWidth: '0',
              },
              '.MuiSvgIcon-root': {
                width: '20px',
                height: '20px',
              },
            }}
            spacing={0.5}
          >
            <Tooltip title={t('projects.projectProperties.editProject')}>
              <Button
                color='secondary'
                variant='contained'
                onClick={(e) => {
                  setEditProjectModalOpen(true)
                  e.stopPropagation()
                }}
              >
                <EditIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t('projects.projectProperties.deleteProject')}>
              <Button
                color='error'
                variant='contained'
                onClick={(e) => {
                  e.stopPropagation()
                  setDeleteModalOpen(true)
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      ) : (
        <Stack direction={'row'} alignItems='center' justifyContent={'between'}>
          <h1>
            <Skeleton width={300} />
          </h1>

          <Stack
            direction={'row'}
            sx={{
              ml: 'auto',
              '.MuiButton-root': {
                width: '40px',
                height: '30px',
                minWidth: '0',
              },
              '.MuiSvgIcon-root': {
                width: '20px',
                height: '20px',
              },
            }}
            spacing={0.5}
          >
            <Button disabled color='secondary' variant='contained'>
              <EditIcon />
            </Button>
            <Button disabled color='error' variant='contained'>
              <DeleteIcon />
            </Button>
          </Stack>
        </Stack>
      )}
      <TemplateManager editable />
      <ProjectDeleteModal deleteModalOpen={deleteModalOpen} setDeleteModalOpen={setDeleteModalOpen} />
      <ProjectPropertiesModal
        addProjectModalOpen={editProjectModalOpen}
        setAddProjectModalOpen={setEditProjectModalOpen}
      />
    </>
  )
}

export default observer(Project)
