import React from 'react'
import { Stack } from '@mui/material'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import AccountManager from '../components/Account/AccountManager'

const Accounts = () => {
  const { t } = useTranslation()

  return (
    <Stack direction={'column'} spacing={3} height={'inherit'} sx={{ alignItems: 'center' }}>
      <h1>{t('account.welcome')}</h1>
      <AccountManager />
    </Stack>
  )
}

export default observer(Accounts)
