import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { RootStore } from './RootStore'
import SampleDocumentService from '../api/SampleDocumentService'
import SampleDocument from '../domain/SampleDocument'
import eventEmitter from '../components/eventEmitter'

export class SampleDocumentStore {
  root: RootStore
  sampleDocumentService: SampleDocumentService
  accountId = ''
  projectId = ''
  templateId = ''
  currentSampleDocument: SampleDocument | null = null
  currentParsedSampleDocument: any = null
  sampleDocuments: SampleDocument[] = []
  isLoading = true

  constructor(root: RootStore) {
    this.root = root
    this.sampleDocumentService = new SampleDocumentService()
    makeAutoObservable(this)
  }

  setCurrentAccountId(accountId: string) {
    this.accountId = accountId
  }

  setCurrentProjectId(projectId: string) {
    this.projectId = projectId
  }

  setCurrentTemplateId(templateId: string) {
    this.templateId = templateId
  }

  async loadSampleDocuments() {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const sampleDocuments = await this.sampleDocumentService.list(this.accountId, this.projectId, this.templateId)
      runInAction(() => {
        this.sampleDocuments = sampleDocuments.map((s) => new SampleDocument(s))
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load samples - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  async loadCurrent(sampleDocumentId: string) {
    try {
      this.isLoading = true
      const sampleDocument = await this.sampleDocumentService.get(
        this.accountId,
        this.projectId,
        this.templateId,
        sampleDocumentId
      )
      runInAction(() => {
        this.currentSampleDocument = new SampleDocument(sampleDocument)
        this.currentParsedSampleDocument = null
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load current - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  clearCurrent() {
    this.currentSampleDocument = null
    this.currentParsedSampleDocument = null
  }

  async updateCurrent() {
    if (!this.currentSampleDocument) {
      eventEmitter.emit('showSnackbar', {
        message: 'No sample document selected',
        severity: 'error',
      })
      return
    }
    try {
      await this.sampleDocumentService.put(this.accountId, this.projectId, this.templateId, this.currentSampleDocument)
      const sampleDocumentIndex = this.sampleDocuments.findIndex((t) => t.id === this.currentSampleDocument?.id)
      runInAction(() => {
        if (sampleDocumentIndex != -1 && this.currentSampleDocument) {
          this.sampleDocuments[sampleDocumentIndex] = this.currentSampleDocument
        }
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update current - ' + error,
        severity: 'error',
      })
    }
  }

  async delete(sampleDocument: SampleDocument) {
    runInAction(() => {
      this.sampleDocuments = this.sampleDocuments.filter((s) => s.id !== sampleDocument.id)
    })
    try {
      await this.sampleDocumentService.delete(this.accountId, this.projectId, this.templateId, sampleDocument.id)
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not delete sample document - ' + error,
        severity: 'error',
      })
    }
  }

  async create(sampleDocument: SampleDocument): Promise<SampleDocument> {
    // Create a skeleton sample document with a unique id
    const skeletonSampleDocument = new SampleDocument(sampleDocument)
    skeletonSampleDocument.setId('loading-' + this.sampleDocuments.length)

    this.sampleDocuments.push(skeletonSampleDocument)
    try {
      const newSampleDocument: SampleDocument = await this.sampleDocumentService.post(
        this.accountId,
        this.projectId,
        this.templateId,
        sampleDocument
      )

      runInAction(() => {
        const skeletonSampleDocumentIndex = this.sampleDocuments.findIndex((t) => t.id === skeletonSampleDocument.id)
        this.sampleDocuments[skeletonSampleDocumentIndex] = newSampleDocument
      })
      return newSampleDocument
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not create sample document - ' + error,
        severity: 'error',
      })
      return Promise.reject(error)
    }
  }

  async createPdf() {
    if (!this.currentSampleDocument) {
      eventEmitter.emit('showSnackbar', {
        message: 'No sample document selected',
        severity: 'error',
      })
      return
    }
    this.sampleDocumentService
      .createPdf(this.accountId, this.projectId, this.templateId, this.currentSampleDocument.id)
      .then((result) => this.downloadURI(result, this.currentSampleDocument?.name ?? 'sampleDocument.pdf'))
      .catch((error) => {
        eventEmitter.emit('showSnackbar', {
          message: 'Could not delete pdf - ' + error,
          severity: 'error',
        })
      })
  }
  async parsePDF(): Promise<any> {
    if (!this.currentSampleDocument) {
      eventEmitter.emit('showSnackbar', {
        message: 'No sample document selected',
        severity: 'error',
      })
      return Promise.reject('No sample document selected')
    }

    try {
      const result = await this.sampleDocumentService.parseSampleDocument(
        this.accountId,
        this.projectId,
        this.templateId,
        this.currentSampleDocument.id
      )
      this.currentParsedSampleDocument = result
      return result
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not parse pdf - ' + error,
        severity: 'error',
      })
      return Promise.reject(error)
    }
  }

  downloadURI(blob: Blob, fileName: string) {
    const link = document.createElement('a')
    link.download = fileName
    link.href = URL.createObjectURL(blob)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
