import { makeAutoObservable } from 'mobx'

export interface IUser {
  id: string | null
  username: string | null
  email: string | null
  firstName: string | null
  lastName: string | null
  jobTitle: string | null
  profilePicture: string | null
  language: string | null
  accounts: string[] | null
  roles: string[] | null
  exp: number | null
}

export class User implements IUser {
  id = ''
  username = ''
  email = ''
  firstName = ''
  lastName = ''
  jobTitle = ''
  profilePicture = ''
  language = ''
  accounts: string[] = []
  exp = null
  roles: string[] = []

  constructor(account: User | null = null) {
    account && Object.assign(this, account)
    makeAutoObservable(this)
  }

  setId(id: string) {
    this.id = id
  }

  setFirstName(firstName: string) {
    this.firstName = firstName
  }

  setLastName(lastName: string) {
    this.lastName = lastName
  }

  setEmail(email: string) {
    this.email = email
  }

  setProfilePicture(profilePicture: string) {
    if (profilePicture === '') {
      this.profilePicture = profilePicture
    } else {
      this.profilePicture = profilePicture.split(',')[1]
    }
  }

  setLanguage(language: string) {
    this.language = language
  }

  setJobtitle(jobTitle: string) {
    this.jobTitle = jobTitle
  }
}
export default User
