import { makeAutoObservable, runInAction } from 'mobx'
import { RootStore } from './RootStore'
import { TransactionResponse } from '../domain/Transaction'
import eventEmitter from '../components/eventEmitter'
import { GetTransactionOptions, getTransactions } from '../api/TransactionService'

export class TransactionStore {
  root: RootStore

  isLoading = true

  constructor(root: RootStore) {
    this.root = root
    makeAutoObservable(this)
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  async loadTransactions(getTransactionOptions: GetTransactionOptions): Promise<TransactionResponse> {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const transactionResponse = await getTransactions({ ...getTransactionOptions })
      runInAction(() => {
        this.isLoading = false
      })
      return transactionResponse
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load transactions - ' + error,
        severity: 'error',
      })
      runInAction(() => {
        this.isLoading = false
      })
      return Promise.reject(error)
    }
  }
}
