import apiRequest from './ApiRequest'

const webApiUrl = '/bff/api/'

export const createPdfBase64 = async (
  accountId: string,
  projectId: string,
  templateId: string,
  pdfFileContent: string
): Promise<Blob> => {
  const options: RequestInit = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      accountId: accountId,
      projectId: projectId,
      templateId: templateId,
      pdfFileContent: pdfFileContent,
    }),
  }
  const request = new Request(webApiUrl + 'engine/createPdfBase64', options)
  return await apiRequest(request, 'blob')
}
