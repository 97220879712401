import React, { ReactChild, ReactNode, useEffect, useState } from 'react'

import '../../styles/PropertiesView.css'
import { observer } from 'mobx-react'
import {
  Bbox,
  Document,
  EmptySpaceRowBreaker,
  FixedHeightRowBreaker,
  ImageField,
  Positioning,
  Section,
  Spot,
  StartEndIdentification,
  StringRowBreaker,
  StructureProperties,
  TableColumn,
  Table,
  TextField,
  DocumentInfo,
  SpotOffset,
  List,
  TextSpot,
  ImageSpot,
  TableCellOptions,
  SmartDetectProperties,
} from '../../domain/DataModelsMobx'
import {
  MenuItem,
  TextField as TextFieldInput,
  Button,
  Checkbox,
  Autocomplete,
  Toolbar,
  FormControlLabel,
  Select,
  InputLabel,
  FormControl,
  Tooltip,
} from '@mui/material'
import {
  searchTypes,
  structureElementsDocument,
  structureElementsHeader,
  structureElementsIllustration,
  structureElementsTextField,
} from '../../domain/StructureTypes'
import ISO6391 from 'iso-639-1'

import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

import { useTranslation } from 'react-i18next'
import { Reorder, useDragControls } from 'framer-motion'

const rowBreakerTypes = ['EmptySpaceRowBreaker', 'StringRowBreaker']
// const rowBreakerTypes = ['EmptySpaceRowBreaker', 'FixedHeightRowBreaker', 'StringRowBreaker'] fixedHeight not yet defined
const tableCellOptionsScope = ['COLUMN_HEADER', 'ROW_HEADER', 'BOTH_HEADER', 'DATA']

const CheckBoxField = observer(
  ({ checked, onChange, label }: { checked: boolean; onChange: (data: any) => void; label: string }) => {
    return <FormControlLabel control={<Checkbox checked={checked} onChange={onChange} />} label={label} />
  }
)

interface LabeledSelectProps {
  children: ReactNode
  label: string
  value: string | string[]
  multiple?: boolean
  onChange: (data: any) => void
}

const LabeledSelect = observer(({ children, label, value, onChange, multiple }: LabeledSelectProps) => {
  return (
    <FormControl>
      <InputLabel htmlFor={'select-label-' + label}>{label}</InputLabel>
      <Select inputProps={{ id: 'select-label-' + label }} multiple={multiple} value={value} onChange={onChange}>
        {children}
      </Select>
    </FormControl>
  )
})

function PropertiesView({
  data,
  spots,
}: {
  data: DocumentInfo | Spot | Document | Section | TextField | ImageField | Table | TableColumn | List
  spots: Spot[]
}) {
  const { t } = useTranslation()

  const CollapseRows = observer(
    ({
      title,
      children,
      startCollapsed,
      onDelete,
    }: {
      title: string | React.ReactNode
      children?: React.ReactNode
      onDelete?: (data: any) => void
      startCollapsed?: boolean
    }) => {
      const [collapsed, setCollapsed] = useState<boolean>(startCollapsed || false)

      return (
        <>
          <div className=' collapsableSectionHeader'>
            <Tooltip
              title={(collapsed ? t('designer.propertiesView.show') : t('designer.propertiesView.hide')) + ' ' + title}
            >
              <Button
                variant='contained'
                className='CollapseButton'
                aria-expanded={!collapsed}
                onClick={() => setCollapsed(!collapsed)}
              >
                {!collapsed ? <RemoveIcon /> : <AddIcon />}
              </Button>
            </Tooltip>
            {title}
            {onDelete && (
              <Tooltip title={t('designer.propertiesView.remove') + ' ' + title}>
                <Button variant='contained' onClick={onDelete}>
                  <ClearIcon />
                </Button>
              </Tooltip>
            )}
          </div>

          {!collapsed && <div className='collapseChildrenWrapper'>{children}</div>}
        </>
      )
    }
  )

  const DocumentsProperties = observer(({ data, spots }: { data: Document; spots: Spot[] }) => {
    return (
      <>
        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.name')}
          value={data.name}
          onChange={(e) => data.setName(e.target.value)}
        />

        <LanguageProperties data={data} />
        <StartEndIdentificationProperties
          startEndIdentification={data.startIdentification}
          spots={spots}
          title={t('designer.propertiesView.startIdentification')}
        />
        <StartEndIdentificationProperties
          startEndIdentification={data.endIdentification}
          spots={spots}
          title={t('designer.propertiesView.endIdentification')}
        />
      </>
    )
  })

  const DocumentInfoProperties = observer(({ data }: { data: DocumentInfo }) => {
    return (
      <>
        <Autocomplete
          size='small'
          freeSolo
          value={data.title ? data.title : ''}
          onChange={(e, n) => data.setTitle(n)}
          onInputChange={(e, n) => data.setTitle(n)}
          options={[]}
          renderInput={(params) => <TextFieldInput {...params} label={t('designer.propertiesView.title')} />}
        />

        <Autocomplete
          size='small'
          freeSolo
          value={data.author ? data.author : ''}
          onChange={(e, n) => data.setAuthor(n)}
          onInputChange={(e, n) => data.setAuthor(n)}
          options={[]}
          renderInput={(params) => <TextFieldInput {...params} label={t('designer.propertiesView.author')} />}
        />

        <Autocomplete
          size='small'
          freeSolo
          value={data.subject ? data.subject : ''}
          onChange={(e, n) => data.setSubject(n)}
          onInputChange={(e, n) => data.setSubject(n)}
          options={[]}
          renderInput={(params) => <TextFieldInput {...params} label={t('designer.propertiesView.subject')} />}
        />

        <Autocomplete
          size='small'
          freeSolo
          value={data.keywords ? data.keywords : ''}
          onChange={(e, n) => data.setKewords(n)}
          onInputChange={(e, n) => data.setKewords(n)}
          options={[]}
          renderInput={(params) => <TextFieldInput {...params} label={t('designer.propertiesView.keywords')} />}
        />

        <div>{t('designer.propertiesView.ignoredIfNotFilled')}</div>
      </>
    )
  })

  const TextSpotProperties = observer(({ data }: { data: TextSpot }) => {
    return (
      <>
        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.name')}
          value={data.name}
          onChange={(e) => data.setName(e.target.value)}
        />

        <CheckBoxField
          label={t('designer.propertiesView.searchWholePage')}
          checked={data.searchWholePage}
          onChange={(e) => data.setSearchWholePage(e.target.checked)}
        />

        {!data.searchWholePage ? <BboxProperties bbox={data.searchArea} /> : <></>}

        <LabeledSelect
          label={t('designer.propertiesView.searchType')}
          value={data.searchType}
          onChange={(e) => data.setSearchType(e.target.value)}
        >
          {searchTypes.map((type, index) => (
            <MenuItem key={index} value={type}>
              {type}
            </MenuItem>
          ))}
        </LabeledSelect>

        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.searchString')}
          value={data.searchString}
          onChange={(e) => data.setSearchString(e.target.value)}
        />
      </>
    )
  })

  const ImageSpotProperties = observer(({ data }: { data: ImageSpot }) => {
    return (
      <>
        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.name')}
          value={data.name}
          onChange={(e) => data.setName(e.target.value)}
        />

        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.imageWidth')}
          type='number'
          value={data.imageWidth}
          onChange={(e) => data.setImageWidth(Number(e.target.value))}
        />

        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.imageHeight')}
          type='number'
          value={data.imageHeight}
          onChange={(e) => data.setImageHeight(Number(e.target.value))}
        />

        <BboxProperties bbox={data.searchArea} />
      </>
    )
  })

  const TextFieldProperties = observer(({ data, spots }: { data: TextField | ImageField | Section; spots: Spot[] }) => {
    return (
      <>
        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.name')}
          value={data.name}
          onChange={(e) => data.setName(e.target.value)}
        />

        <CheckBoxField
          label={t('designer.propertiesView.enabled')}
          checked={data.enabled}
          onChange={(e) => data.setEnabled(e.target.checked)}
        />

        <StructurePropertiesProperties data={data} />
        <StartEndIdentificationProperties
          startEndIdentification={data.startIdentification}
          spots={spots}
          title={t('designer.propertiesView.startIdentification')}
        />
        <StartEndIdentificationProperties
          startEndIdentification={data.endIdentification}
          spots={spots}
          title={t('designer.propertiesView.endIdentification')}
        />
        <BboxProperties bbox={data.bbox} />
        <PositioningProperties positioning={data.positioning} spots={spots} />
        {data.type === 'Section' && <SmartDetectProperties data={data as Section} />}
      </>
    )
  })

  const TableProperties = observer(({ data, spots }: { data: Table; spots: Spot[] }) => {
    return (
      <>
        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.name')}
          value={data.name}
          onChange={(e) => data.setName(e.target.value)}
        />

        <CheckBoxField
          label={t('designer.propertiesView.enabled')}
          checked={data.enabled}
          onChange={(e) => data.setEnabled(e.target.checked)}
        />

        <StructurePropertiesProperties data={data} />
        <StartEndIdentificationProperties
          startEndIdentification={data.startIdentification}
          spots={spots}
          title={t('designer.propertiesView.startIdentification')}
        />
        <StartEndIdentificationProperties
          startEndIdentification={data.endIdentification}
          spots={spots}
          title={t('designer.propertiesView.endIdentification')}
        />
        <PositioningProperties positioning={data.positioning} spots={spots} />

        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.rowHeader')}
          type='number'
          value={data.rowHeader}
          onChange={(e) => data.setRowHeader(Number(e.target.value))}
        />

        <CheckBoxField
          label={t('designer.propertiesView.createVirtualHeaderRow')}
          checked={data.createVirtualHeaderRow}
          onChange={(e) => data.setCreateVirtualHeaderRow(e.target.checked)}
        />

        <CollapseRows title={t('designer.propertiesView.rowBreaker')}>
          <RowBreakerProperty data={data} />
        </CollapseRows>
        <BboxProperties bbox={data.bbox} />
        <TableCellOptionsProperties table={data} />
      </>
    )
  })

  const TableColumnProperties = observer(({ data }: { data: TableColumn }) => {
    return (
      <>
        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.name')}
          value={data.name}
          onChange={(e) => data.setName(e.target.value)}
        />

        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.emptyCellText')}
          value={data.emptyCellText}
          onChange={(e) => data.setEmptyCellText(e.target.value)}
        />

        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.alternateDescription')}
          value={data.alternateDescription}
          onChange={(e) => data.setAlternateDescription(e.target.value)}
        />

        <BboxProperties bbox={data.bbox} />
      </>
    )
  })

  const ReorderItem = observer(({ children, item }: { children?: React.ReactNode; item: any }) => {
    const controls = useDragControls()

    return (
      <Reorder.Item
        key={item.id}
        value={item}
        as={'div'}
        dragListener={false}
        dragControls={controls}
        className='reorderItem'
      >
        {children}
        <DragIndicatorIcon
          onPointerDown={(e) => controls.start(e)}
          sx={{
            color: 'lightGray',
            cursor: 'grab',
            fontSize: '22px !important',
            position: 'absolute',
            top: ' 7px',
            right: '0',
          }}
        />
      </Reorder.Item>
    )
  })

  const TableCellOptionsProperties = observer(({ table }: { table: Table }) => {
    return (
      <CollapseRows
        title={
          <>
            {t('designer.propertiesView.tableCellOptions')}
            <Tooltip title={t('designer.propertiesView.add') + ' ' + t('designer.propertiesView.tableCellOptions')}>
              <Button variant='contained' onClick={() => table.addTableCellOptions()}>
                <AddIcon />
              </Button>
            </Tooltip>
          </>
        }
      >
        <Reorder.Group
          values={table.tableCellOptions}
          onReorder={table.setTableCellOptions}
          className={'reorderGroup'}
          as={'div'}
        >
          {table.tableCellOptions.map((item: TableCellOptions, itemIndex: number) => {
            return (
              <ReorderItem key={item.id} item={item}>
                <CollapseRows
                  key={itemIndex}
                  onDelete={() => {
                    table.deleteTableCellOptions(itemIndex)
                  }}
                  startCollapsed
                  title={item.name}
                >
                  <CheckBoxField
                    label={t('designer.propertiesView.enabled')}
                    checked={item.enabled}
                    onChange={(e) => item.setEnabled(e.target.checked)}
                  />

                  <TextFieldInput
                    size='small'
                    label={t('designer.propertiesView.name')}
                    value={item.name}
                    onChange={(e) => item.setName(e.target.value)}
                  />

                  <LabeledSelect
                    label={t('designer.propertiesView.scope')}
                    value={item.scope}
                    onChange={(event) => item.setScope(event.target.value)}
                  >
                    {tableCellOptionsScope.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {t('designer.propertiesView.tableCellOptionsScope.' + type)}
                      </MenuItem>
                    ))}
                  </LabeledSelect>

                  <TextFieldInput
                    size='small'
                    label={t('designer.propertiesView.fromRow')}
                    type='number'
                    value={item.fromRow}
                    onChange={(e) => item.setFromRow(Number(e.target.value))}
                  />

                  <TextFieldInput
                    size='small'
                    label={t('designer.propertiesView.toRow')}
                    type='number'
                    value={item.toRow}
                    onChange={(e) => item.setToRow(Number(e.target.value))}
                  />

                  <TextFieldInput
                    size='small'
                    label={t('designer.propertiesView.fromColumn')}
                    type='number'
                    value={item.fromColumn}
                    inputProps={
                      data.type === 'Table' ? { min: '1', max: (data as Table).columns.length } : { min: '1' }
                    }
                    onChange={(e) => item.setFromColumn(Number(e.target.value))}
                  />

                  <TextFieldInput
                    size='small'
                    label={t('designer.propertiesView.toColumn')}
                    type='number'
                    value={item.toColumn}
                    inputProps={
                      data.type === 'Table' ? { min: '1', max: (data as Table).columns.length } : { min: '1' }
                    }
                    onChange={(e) => item.setFromColumn(Number(e.target.value))}
                  />
                </CollapseRows>
              </ReorderItem>
            )
          })}
        </Reorder.Group>
      </CollapseRows>
    )
  })

  const ListProperties = observer(({ data, spots }: { data: List; spots: Spot[] }) => {
    return (
      <>
        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.name')}
          value={data.name}
          onChange={(e) => data.setName(e.target.value)}
        />
        <StructurePropertiesProperties data={data} />
        <StartEndIdentificationProperties
          startEndIdentification={data.startIdentification}
          spots={spots}
          title={t('designer.propertiesView.startIdentification')}
        />
        <StartEndIdentificationProperties
          startEndIdentification={data.endIdentification}
          spots={spots}
          title={t('designer.propertiesView.endIdentification')}
        />
        <CollapseRows title={t('designer.propertiesView.rowBreaker')}>
          <RowBreakerProperty data={data} list />
        </CollapseRows>
        <BboxProperties bbox={data.bbox} />
      </>
    )
  })

  const RowBreakerProperty = observer(({ data, list }: { data: Table | List; list?: boolean }) => {
    const [rowBreaker, setRowBreaker] = useState<string>(rowBreakerTypes[1])
    return (
      <>
        <Reorder.Group values={data.rowBreakers} onReorder={data.setRowBreakers} className={'reorderGroup'} as={'div'}>
          {data.rowBreakers.map(
            (item: EmptySpaceRowBreaker | FixedHeightRowBreaker | StringRowBreaker, itemIndex: number) => (
              <ReorderItem key={item.id} item={item}>
                <CollapseRows
                  onDelete={() => data.deleteRowBreaker(itemIndex)}
                  title={t('designer.propertiesView.rowBreakers.' + item.type)}
                  startCollapsed
                >
                  <CheckBoxField
                    label={t('designer.propertiesView.rowBreakers.' + item.type)}
                    checked={item.enabled}
                    onChange={(e) => item.setEnabled(e.target.checked)}
                  />
                  {!list ? (
                    <>
                      <TextFieldInput
                        size='small'
                        label={t('designer.propertiesView.fromColumn')}
                        type='number'
                        value={item.fromColumn}
                        inputProps={
                          data.type === 'Table' ? { min: '1', max: (data as Table).columns.length } : { min: '1' }
                        }
                        onChange={(e) => item.setFromColumn(Number(e.target.value))}
                      />

                      <TextFieldInput
                        size='small'
                        label={t('designer.propertiesView.toColumn')}
                        type='number'
                        value={item.toColumn}
                        inputProps={
                          data.type === 'Table' ? { min: '1', max: (data as Table).columns.length } : { min: '1' }
                        }
                        onChange={(e) => item.setToColumn(Number(e.target.value))}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {item.type === 'EmptySpaceRowBreaker' ? (
                    <>
                      <TextFieldInput
                        size='small'
                        label={t('designer.propertiesView.spaceThreshold')}
                        type='number'
                        value={(item as EmptySpaceRowBreaker).spaceThreshold}
                        onChange={(e) => (item as EmptySpaceRowBreaker).setSpaceThreshold(Number(e.target.value))}
                      />

                      <CheckBoxField
                        label={t('designer.propertiesView.breakOnEveryLine')}
                        checked={(item as EmptySpaceRowBreaker).breakOnEveryLine}
                        onChange={(e) => (item as EmptySpaceRowBreaker).setBreakOnEveryLine(e.target.checked)}
                      />

                      <CheckBoxField
                        label={t('designer.propertiesView.topALigned')}
                        checked={(item as EmptySpaceRowBreaker).topAligned}
                        onChange={(e) => (item as EmptySpaceRowBreaker).setTopAligned(e.target.checked)}
                      />
                    </>
                  ) : item.type === 'FixedHeightRowBreaker' ? (
                    <TextFieldInput
                      size='small'
                      label={t('designer.propertiesView.rowHeight')}
                      type='number'
                      value={(item as FixedHeightRowBreaker).rowHeight}
                      onChange={(e) => (item as FixedHeightRowBreaker).setRowHeight(Number(e.target.value))}
                    />
                  ) : item.type === 'StringRowBreaker' ? (
                    <>
                      <CheckBoxField
                        label={t('designer.propertiesView.useRegex')}
                        checked={(item as StringRowBreaker).useRegex}
                        onChange={(e) => (item as StringRowBreaker).setUseRegex(e.target.checked)}
                      />

                      {(item as StringRowBreaker).useRegex ? (
                        <Autocomplete
                          size='small'
                          freeSolo
                          options={['\\•', '\\d.*$']}
                          value={(item as StringRowBreaker).rowBreakString}
                          onChange={(e, n) => (item as StringRowBreaker).setRowBreakString(n ? n : '')}
                          onInputChange={(e, n) => (item as StringRowBreaker).setRowBreakString(n ? n : '')}
                          renderInput={(params) => (
                            <TextFieldInput label={t('designer.propertiesView.rowBreakString')} />
                          )}
                        />
                      ) : (
                        <TextFieldInput
                          size='small'
                          label={t('designer.propertiesView.rowBreakString')}
                          value={(item as StringRowBreaker).rowBreakString}
                          onChange={(e) => (item as StringRowBreaker).setRowBreakString(e.target.value)}
                        />
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </CollapseRows>
              </ReorderItem>
            )
          )}
        </Reorder.Group>

        <div className={'multiInput'}>
          <LabeledSelect
            label={t('designer.propertiesView.addNewRow')}
            value={rowBreaker}
            onChange={(event) => setRowBreaker(event.target.value)}
          >
            {rowBreakerTypes.map((type, index) => (
              <MenuItem key={index} value={type}>
                {t('designer.propertiesView.rowBreakers.' + type)}
              </MenuItem>
            ))}
          </LabeledSelect>
          <Tooltip title={t('designer.propertiesView.add')}>
            <Button variant='contained' onClick={() => data.addRowBreaker(rowBreaker)}>
              <AddIcon />
            </Button>
          </Tooltip>
        </div>
      </>
    )
  })

  const StructurePropertiesProperties = observer(
    ({ data }: { data: TextField | ImageField | Section | Table | List }) => {
      return (
        <CollapseRows title={t('designer.propertiesView.structureProperties')}>
          {data.type === 'TextField' ? (
            <LabeledSelect
              label={t('designer.propertiesView.structureType')}
              value={data.structureProperties.structureType}
              onChange={(e) => data.structureProperties.setStructureType(e.target.value)}
            >
              {structureElementsTextField.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </LabeledSelect>
          ) : (
            <></>
          )}
          {data.type === 'ImageField' ? (
            <LabeledSelect
              label={t('designer.propertiesView.structureType')}
              value={data.structureProperties.structureType}
              onChange={(e) => data.structureProperties.setStructureType(e.target.value)}
            >
              {structureElementsIllustration.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </LabeledSelect>
          ) : (
            <></>
          )}
          {data.type === 'Section' ? (
            <LabeledSelect
              label={t('designer.propertiesView.structureType')}
              value={data.structureProperties.structureType}
              onChange={(e) => data.structureProperties.setStructureType(e.target.value)}
            >
              {structureElementsDocument.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </LabeledSelect>
          ) : (
            <></>
          )}
          <LanguageProperties data={data.structureProperties} />

          <TextFieldInput
            size='small'
            label={t('designer.propertiesView.title')}
            value={data.structureProperties.title || ''}
            onChange={(e) => data.structureProperties.setTitle(e.target.value)}
          />

          <TextFieldInput
            size='small'
            label={t('designer.propertiesView.alternateDescription')}
            value={data.structureProperties.alternateDescription || ''}
            onChange={(e) => data.structureProperties.setAlternateDescription(e.target.value)}
          />

          <TextFieldInput
            size='small'
            label={t('designer.propertiesView.actualText')}
            value={data.structureProperties.actualText || ''}
            onChange={(e) => data.structureProperties.setActualText(e.target.value)}
          />
        </CollapseRows>
      )
    }
  )

  const SmartDetectProperties = observer(({ data }: { data: Section }) => {
    return (
      <>
        <CheckBoxField
          label={t('designer.propertiesView.enableSmartDetect')}
          checked={data.enableSmartDetect}
          onChange={(e) => data.setEnableSmartDetect(e.target.checked)}
        />

        {data.enableSmartDetect && (
          <CollapseRows title={t('designer.propertiesView.SmartDetectProperties')}>
            <CheckBoxField
              label={t('designer.propertiesView.makeBoldLineHeader')}
              checked={data.smartDetectProperties.makeBoldLineHeader}
              onChange={(e) => data.smartDetectProperties.setMakeBoldLineHeader(e.target.checked)}
            />

            <LabeledSelect
              label={t('designer.propertiesView.boldHeaderStructureType')}
              value={data.smartDetectProperties.boldHeaderStructureType}
              onChange={(e) => data.smartDetectProperties.setBoldHeaderStructureType(e.target.value)}
            >
              {structureElementsHeader.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </LabeledSelect>

            <TextFieldInput
              size='small'
              label={t('designer.propertiesView.joinLineBelowRatio')}
              type='number'
              value={data.smartDetectProperties.joinLineBelowRatio}
              onChange={(e) => data.smartDetectProperties.setJoinLineBelowRatio(Number(e.target.value))}
            />

            <TextFieldInput
              size='small'
              label={t('designer.propertiesView.lineTolerance')}
              type='number'
              inputProps={{
                step: 0.1,
              }}
              value={data.smartDetectProperties.lineTolerance}
              onChange={(e) => data.smartDetectProperties.setLineTolerance(parseFloat(e.target.value))}
            />

            <CheckBoxField
              label={t('designer.propertiesView.sortAllElements')}
              checked={data.smartDetectProperties.sortAllElements}
              onChange={(e) => data.smartDetectProperties.setSortAllElements(e.target.checked)}
            />
          </CollapseRows>
        )}
      </>
    )
  })

  const LanguageProperties = observer(({ data }: { data: StructureProperties | Document }) => {
    return (
      <Autocomplete
        size='small'
        freeSolo
        options={ISO6391.getAllNames()}
        value={data.language ? ISO6391.getName(data.language) : ''}
        onChange={(e, n) => data.setLanguage(n ? ISO6391.getCode(n) : null)}
        renderInput={(params) => <TextFieldInput {...params} label={t('designer.propertiesView.language')} />}
      />
    )
  })

  const StartEndIdentificationProperties = observer(
    ({
      startEndIdentification,
      spots,
      title,
    }: {
      startEndIdentification: StartEndIdentification
      spots: Spot[]
      title: string
    }) => {
      return (
        <CollapseRows title={title}>
          <CheckBoxField
            label={title + ' ' + t('designer.propertiesView.enabled')}
            checked={startEndIdentification.enabled}
            onChange={(e) => startEndIdentification.setEnabled(e.target.checked)}
          />
          <LabeledSelect
            label={title + ' ' + t('designer.propertiesView.spots')}
            multiple
            value={startEndIdentification.spots}
            onChange={(e) => Array.isArray(e.target.value) && startEndIdentification.setSpots(e.target.value)}
          >
            {spots
              .slice()
              .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
              .map((spot, index) => (
                <MenuItem key={index} value={spot.id}>
                  {spot.name}
                </MenuItem>
              ))}
          </LabeledSelect>
        </CollapseRows>
      )
    }
  )

  const BboxProperties = observer(({ bbox }: { bbox: Bbox }) => {
    return (
      <CollapseRows title={t('designer.propertiesView.boundingBox')}>
        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.x')}
          type='number'
          value={bbox.x}
          onChange={(e) => bbox.setX(Number(e.target.value))}
        />

        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.y')}
          type='number'
          value={bbox.y}
          onChange={(e) => bbox.setY(Number(e.target.value))}
        />

        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.width')}
          type='number'
          value={bbox.width}
          onChange={(e) => bbox.setWidth(Number(e.target.value))}
        />

        <TextFieldInput
          size='small'
          label={t('designer.propertiesView.height')}
          type='number'
          value={bbox.height}
          onChange={(e) => bbox.setHeight(Number(e.target.value))}
        />
      </CollapseRows>
    )
  })

  const PositioningProperties = observer(({ positioning, spots }: { positioning: Positioning; spots: Spot[] }) => {
    return (
      <CollapseRows title={t('designer.propertiesView.positioning')}>
        <CheckBoxField
          label={t('designer.propertiesView.top')}
          checked={positioning.top}
          onChange={(e) => {
            positioning.setTop(e.target.checked)
          }}
        />

        <CheckBoxField
          label={t('designer.propertiesView.left')}
          checked={positioning.left}
          onChange={(e) => positioning.setLeft(e.target.checked)}
        />

        <CheckBoxField
          label={t('designer.propertiesView.bottom')}
          checked={positioning.bottom}
          onChange={(e) => positioning.setBottom(e.target.checked)}
        />

        <CheckBoxField
          label={t('designer.propertiesView.right')}
          checked={positioning.right}
          onChange={(e) => positioning.setRight(e.target.checked)}
        />

        <CollapseRows
          title={
            <>
              {t('designer.propertiesView.spotOffset')}
              <Tooltip title={t('designer.propertiesView.add') + ' ' + t('designer.propertiesView.spotOffset')}>
                <Button variant='contained' onClick={() => positioning.addSpotOffset(spots[0]?.id)}>
                  <AddIcon />
                </Button>
              </Tooltip>
            </>
          }
        >
          <Reorder.Group
            values={positioning.spotOffsets}
            onReorder={positioning.setSpotOffsets}
            className='reorderGroup'
            as={'div'}
          >
            {positioning.spotOffsets.map((item: SpotOffset, itemIndex: number) => (
              <ReorderItem key={item.id} item={item}>
                <CollapseRows
                  key={itemIndex}
                  onDelete={() => {
                    positioning.deleteSpotOffset(itemIndex)
                  }}
                  title={t('designer.propertiesView.spotOffset') + ' ' + itemIndex}
                  startCollapsed
                >
                  <LabeledSelect
                    label={t('designer.propertiesView.spot')}
                    value={item.spot}
                    onChange={(e) => item.setSpot(e.target.value)}
                  >
                    {spots
                      .slice()
                      .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
                      .map((spot, index) => (
                        <MenuItem key={index} value={spot.id}>
                          {spot.name}
                        </MenuItem>
                      ))}
                  </LabeledSelect>

                  <TextFieldInput
                    size='small'
                    label={t('designer.propertiesView.top')}
                    type='number'
                    value={item.offsetTop}
                    onChange={(e) => item.setOffsetTop(Number(e.target.value))}
                  />

                  <TextFieldInput
                    size='small'
                    label={t('designer.propertiesView.left')}
                    type='number'
                    value={item.offsetLeft}
                    onChange={(e) => item.setOffsetLeft(Number(e.target.value))}
                  />

                  <TextFieldInput
                    size='small'
                    label={t('designer.propertiesView.right')}
                    type='number'
                    value={item.offsetRight}
                    onChange={(e) => item.setOffsetRight(Number(e.target.value))}
                  />

                  <TextFieldInput
                    size='small'
                    label={t('designer.propertiesView.bottom')}
                    type='number'
                    value={item.offsetBottom}
                    onChange={(e) => item.setOffsetBottom(Number(e.target.value))}
                  />
                </CollapseRows>
              </ReorderItem>
            ))}
          </Reorder.Group>
        </CollapseRows>
      </CollapseRows>
    )
  })

  return (
    <Toolbar className='styled-table'>
      {data.type === 'TextField' || data.type === 'ImageField' || data.type === 'Section' ? (
        <TextFieldProperties data={data as TextField} spots={spots} />
      ) : data.type === 'Document' ? (
        <DocumentsProperties data={data as Document} spots={spots} />
      ) : data.type === 'DocumentInfo' ? (
        <DocumentInfoProperties data={data as DocumentInfo} />
      ) : data.type === 'TextSpot' ? (
        <TextSpotProperties data={data as TextSpot} />
      ) : data.type === 'ImageSpot' ? (
        <ImageSpotProperties data={data as ImageSpot} />
      ) : data.type === 'Table' ? (
        <TableProperties data={data as Table} spots={spots} />
      ) : data.type === 'List' ? (
        <ListProperties data={data as List} spots={spots} />
      ) : (
        data.type === 'TableColumn' && <TableColumnProperties data={data as TableColumn} />
      )}
    </Toolbar>
  )
}

export default observer(PropertiesView)
