import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import { Button, Container, Modal, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRootStore } from '../../providers/RootStoreProvider'
import { useNavigate, useParams } from 'react-router-dom'

interface UserDeleteModalProps {
  deleteModalOpen: boolean
  setDeleteModalOpen: (data: boolean) => void
  selectedUser: string | null
}

const UserDeleteModal: React.FC<UserDeleteModalProps> = ({ deleteModalOpen, setDeleteModalOpen, selectedUser }) => {
  const { t } = useTranslation()
  const { accountStore } = useRootStore()
  const { accountId, userId } = useParams()
  const navigate = useNavigate()
  const [deleteSubmitted, setDeleteSubmitted] = useState<boolean>(false)

  const handleClose = useCallback(() => {
    setDeleteModalOpen(false)
  }, [setDeleteModalOpen])

  const handleDelete = useCallback(async () => {
    if (!selectedUser) return

    setDeleteSubmitted(true)
    try {
      selectedUser &&
        (await accountStore.removeUser(selectedUser).then(() => {
          navigate(`/accounts/${accountId}/account`)
          accountStore.loadUsers()
          handleClose()
        }))
    } finally {
      setDeleteSubmitted(false)
    }
  }, [accountStore, accountId, userId, handleClose, selectedUser])

  return (
    <Modal open={deleteModalOpen} onClose={handleClose}>
      <Container
        maxWidth='xs'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'var(--paper-color)',
          borderRadius: 1.5,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          padding: 3,
        }}
      >
        <Stack alignItems='center' textAlign='center'>
          <Typography variant='h6' gutterBottom>
            {t('users.delete.delete')}
          </Typography>
        </Stack>

        <Stack direction='row' justifyContent='center' spacing={2} mt={2}>
          <Button color='error' variant='contained' disabled={deleteSubmitted} onClick={handleDelete}>
            {t('users.delete.continue')}
          </Button>
          <Button variant='outlined' disabled={deleteSubmitted} onClick={handleClose}>
            {t('users.delete.cancel')}
          </Button>
        </Stack>
      </Container>
    </Modal>
  )
}

export default observer(UserDeleteModal)
