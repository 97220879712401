import React, { useEffect, useState } from 'react'
import { Button, Skeleton, Stack } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useRootStore } from '../../providers/RootStoreProvider'
import ArticleIcon from '@mui/icons-material/Article'
import { observer } from 'mobx-react'
import TemplatePropertiesModal from './TemplatePropertiesModal'
import { Paper } from '../Paper'
import RowIElement from '../RowIElement'
import { useTranslation } from 'react-i18next'

const TemplateManager = ({ editable }: { editable?: boolean }) => {
  const { t } = useTranslation()

  const { accountId, projectId } = useParams()
  const navigate = useNavigate()
  const { templateStore } = useRootStore()
  const [addTemplateModalOpen, setAddTemplateModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (accountId && projectId) {
      templateStore.setCurrentAccountId(accountId)
      templateStore.setCurrentProjectId(projectId)
      templateStore.loadTemplates()
      templateStore.clearCurrent()
    }
  }, [accountId])

  return (
    <>
      <Paper skeleton={templateStore.isLoading}>
        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} className='PaperHeader'>
          <h2>{t('templates.templateManager.templates')}</h2>
          {editable && (
            <Button
              variant='contained'
              color='success'
              onClick={() => setAddTemplateModalOpen(true)}
              disabled={templateStore.isLoading}
            >
              + {t('templates.templateManager.addTemplate')}
            </Button>
          )}
        </Stack>
        <Stack sx={{ overflow: 'auto' }} spacing={1}>
          {!templateStore.isLoading ? (
            templateStore.templates.map((t, i) => (
              <RowIElement
                key={'template-' + i}
                onClick={() => {
                  navigate('/accounts/' + accountId + '/projects/' + projectId + '/templates/' + t.id)
                }}
                title={t.name}
                underTitle={t.description}
                icon={<ArticleIcon />}
              />
            ))
          ) : (
            <>
              <Skeleton variant='rounded' sx={{ background: 'var(--light-gray-color)' }} width={'100%'} height={65} />
              <Skeleton variant='rounded' sx={{ background: 'var(--light-gray-color)' }} width={'100%'} height={65} />
            </>
          )}
        </Stack>
      </Paper>
      <TemplatePropertiesModal
        addTemplateModalOpen={addTemplateModalOpen}
        setAddTemplateModalOpen={setAddTemplateModalOpen}
      />
    </>
  )
}

export default observer(TemplateManager)
