/**
 * setup.ts
 *
 * This module sets up Mock Service Worker (MSW) for the application.
 *
 * Purpose:
 * Initializes MSW in development environment for local testing.
 *
 * Exports:
 * - setupMocks: Function to conditionally start the MSW worker
 *
 * Behavior:
 * 1. Checks if the environment is development
 * 2. Verifies if the application is running on localhost
 * 3. If conditions are met, starts the MSW worker with specific options
 * 4. If not in development or not on localhost, resolves immediately
 *
 * Configuration:
 * - Uses 'bypass' for unhandled requests
 * - Sets the service worker URL to '/mockServiceWorker.js'
 *
 * Note:
 * MSW is not activated in production environments.
 */

import { worker } from './browser'

export const setupMocks = () => {
  if (process.env.NODE_ENV === 'development') {
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      return worker.start({
        onUnhandledRequest: 'bypass',
        serviceWorker: {
          url: '/mockServiceWorker.js',
        },
      })
    } else {
      console.warn('MSW is not running in production.')
    }
  }
  return Promise.resolve()
}
