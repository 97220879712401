import React from 'react'
import { Checkbox, IconButton, Stack, Tooltip } from '@mui/material'

import { observer } from 'mobx-react'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'

const RowElement = ({
  icon,
  title,
  underTitle,
  onClick,
  onCheck,
  secondButton,
  checked,
  onRemove,
  disabled,
}: {
  icon: React.ReactNode
  title: string
  underTitle?: string
  onClick?: (e: any) => void
  secondButton?: React.ReactNode
  onCheck?: (e: any) => void
  checked?: boolean
  onRemove?: (e: any) => void
  disabled?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <Stack
      sx={{
        opacity: disabled ? 0.5 : 1,
        color: 'var(--secondary-color)',
        p: 1.2,
        display: 'flex',
        background: 'var(--light-gray-color)',
        borderRadius: 1,
        alignItems: 'center',
        justifyContent: 'left',
        ':hover': !disabled
          ? {
              opacity: 0.9,
              backgroundImage: 'linear-gradient(rgb(0 0 0/2%) 0 0)',
              cursor: onClick ? 'pointer' : '',
            }
          : {},
        '& .MuiSvgIcon-root': {
          color: 'var(--primary-color)',
        },
      }}
      spacing={0.5}
      direction={'row'}
      tabIndex={0}
      onClick={onClick && onClick}
      role='button'
      onKeyDown={(e) => {
        if (!disabled && onClick && (e.key === 'Enter' || e.key === ' ')) {
          e.preventDefault()
          onClick && onClick(e)
        }
      }}
    >
      {onCheck && (
        <Checkbox
          inputProps={{ 'aria-label': t('elementRow.checkOf') }}
          disabled={disabled}
          checked={checked}
          onClick={onCheck}
          sx={{
            color: 'var(--secondary-color)',
            '&.Mui-checked': {
              color: 'var(--secondary-color)',
            },
          }}
        />
      )}
      <Stack
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: '2rem',
          },
        }}
      >
        {icon}
      </Stack>
      <Stack
        sx={{
          ml: 1,
          h3: { fontSize: '1.17em', margin: 0 },
          h4: { fontSize: 12, m: 0, mt: '-8px', fontWeight: 500, ml: '1px' },
          textAlign: 'left',
          justifyContent: 'center',
        }}
      >
        <h3>{title}</h3>
        {underTitle && <h4>{underTitle}</h4>}
      </Stack>
      <Stack ml={'auto!important'} direction={'row'}>
        {secondButton && secondButton}
        {onRemove && (
          <Tooltip title={t('elementRow.remove')}>
            <span>
              <IconButton disabled={disabled} onClick={onRemove}>
                <ClearIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  )
}

export default observer(RowElement)
