import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { Rnd } from 'react-rnd'
import '../../styles/CanvasView.css'
import { observer } from 'mobx-react'
import {
  Bbox,
  DOMTemplate,
  Document,
  ImageField,
  Section,
  Spot,
  TableColumn,
  Table,
  TextField,
  undoManager,
  List,
} from '../../domain/DataModelsMobx'

type Position = {
  width: number
  height: number
  y: number
  x: number
}

const CanvasView = ({
  domTemplate,
  selectedUID,
  multiSelectedUID,
  setSelectedData,
  scale,
  selectedProducedData,
}: {
  domTemplate: DOMTemplate
  selectedUID: string
  multiSelectedUID: string[]
  setSelectedData: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: Spot | Document | Section | TextField | ImageField | Table | TableColumn | List | null
  ) => void
  scale: number
  selectedProducedData: any
}) => {
  const isSectionVisible = (section: any): boolean => {
    return (
      !!section &&
      ((selectedUID || multiSelectedUID[0]) === section.id ||
        section.children?.some((child: any) => isSectionVisible(child)) ||
        section.children?.some(
          (child: any) =>
            (child.type === 'Section' && child.children?.some((grandchild: any) => isSectionVisible(grandchild))) ||
            child.columns?.some((column: any) => isSectionVisible(column))
        ) ||
        false)
    )
  }
  return useMemo(
    () => (
      <div className='CanvasArea' style={{ position: 'relative', transform: 'scale(' + scale + ')' }}>
        {domTemplate.spots.map(
          (spot: Spot, spotIndex: number) =>
            !spot.searchWholePage &&
            selectedUID === spot.id && (
              <AreaObject
                key={spot.id + '-spot-' + spotIndex}
                spot={spot.searchArea}
                selected={selectedUID === spot.id}
                onSelect={(e) => {
                  setSelectedData(e, spot)
                }}
                scale={scale}
              />
            )
        )}

        {domTemplate.documents.map((document: Document, index: number) => (
          <div key={document.id + '-' + index} className='CanvasArea-Relative'>
            {document.sections.map(
              (section: Section, index: number) =>
                section.enabled && (
                  <div
                    style={{ display: isSectionVisible(section) ? 'block' : 'none' }}
                    key={section.id + '-section-' + index}
                  >
                    <CanvasSection
                      section={section}
                      selectedUID={selectedUID}
                      onSelect={setSelectedData}
                      multiSelectedUID={multiSelectedUID}
                      scale={scale}
                    />
                  </div>
                )
            )}
          </div>
        ))}
      </div>
    ),
    [domTemplate, selectedUID, scale, selectedProducedData, setSelectedData]
  )
}

const CanvasSection = ({
  section,
  selectedUID,
  onSelect,
  multiSelectedUID,
  scale,
}: {
  section: Section
  selectedUID: string
  onSelect: (
    event: any,
    data: Spot | Document | Section | TextField | ImageField | Table | TableColumn | List | null
  ) => void
  multiSelectedUID: string[]
  scale: number
}) => {
  return (
    <AreaObject
      spot={section.bbox}
      selected={selectedUID === section.id}
      onSelect={(e) => {
        onSelect(e, section)
      }}
      scale={scale}
    >
      {section.children.map((child: TextField | ImageField | Table | Section, index: number) =>
        child.enabled && child.type === 'Section' ? (
          <CanvasSection
            key={child.id}
            section={child as Section}
            selectedUID={selectedUID}
            onSelect={onSelect}
            multiSelectedUID={multiSelectedUID}
            scale={scale}
          />
        ) : (
          <AreaObject
            key={child.id}
            spot={child.bbox}
            selected={selectedUID === child.id}
            onSelect={(e) => {
              onSelect(e, child)
            }}
            scale={scale}
          >
            {child.type === 'Table' &&
              (child as Table).columns &&
              (child as Table).columns.map((column: TableColumn, index: number) => (
                <AreaObject
                  key={column.id}
                  spot={column.bbox}
                  selected={selectedUID === column.id}
                  onSelect={(e) => {
                    onSelect(e, column)
                  }}
                  scale={scale}
                />
              ))}

            {child.type === 'Table' && multiSelectedUID[0] === child.id && (
              <MultiSelectDraggableObject
                data={child as Table}
                multiSelectedUID={multiSelectedUID.slice(1)}
                onSelect={onSelect}
                scale={scale}
              />
            )}
          </AreaObject>
        )
      )}

      {multiSelectedUID[0] === section.id && (
        <MultiSelectDraggableObject
          data={section}
          multiSelectedUID={multiSelectedUID.slice(1)}
          onSelect={onSelect}
          scale={scale}
        />
      )}
    </AreaObject>
  )
}

const AreaObject = observer(
  ({
    children,
    spot,
    selected,
    onSelect,
    scale,
  }: {
    children?: React.ReactNode
    spot: Bbox
    selected: boolean
    onSelect: (event: any) => void
    scale: number
  }) => {
    const handleDragStop = (e: any, d: any) => {
      e.stopPropagation()
      spot.setAll({
        ...spot,
        y: Math.round(d.y),
        x: Math.round(d.x),
      })
    }

    const handleResizeStop = (e: any, direction: any, ref: any, delta: any, position: any) => {
      e.stopPropagation()
      spot.setAll({
        y: Math.round(position.y),
        x: Math.round(position.x),
        width: Math.round(spot.width + delta.width),
        height: Math.round(spot.height + delta.height),
      })
    }

    const handleSelect = (e: any) => {
      onSelect(e)
    }

    const handleKeyDown = useCallback(
      (e: KeyboardEvent) => {
        const arrowKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']
        arrowKeys.includes(e.key) && e.preventDefault()
        if (e.ctrlKey) {
          switch (e.key) {
            case 'ArrowUp':
              spot.setHeight(spot.height - 2)
              break
            case 'ArrowDown':
              spot.setHeight(spot.height + 2)
              break
            case 'ArrowLeft':
              spot.setWidth(spot.width - 2)
              break
            case 'ArrowRight':
              spot.setWidth(spot.width + 2)
              break
            default:
              break
          }
          e.preventDefault()
        } else {
          switch (e.key) {
            case 'ArrowUp':
              spot.setY(spot.y - 2)
              break
            case 'ArrowDown':
              spot.setY(spot.y + 2)
              break
            case 'ArrowLeft':
              spot.setX(spot.x - 2)
              break
            case 'ArrowRight':
              spot.setX(spot.x + 2)
              break
            default:
              break
          }
        }
      },
      [spot]
    )

    return (
      <>
        <div className={selected ? 'selectBox selected' : 'selectBox'} style={{ top: spot.y, left: spot.x }}>
          <div onClick={handleSelect} style={{ width: spot.width }}></div>
          <div onClick={handleSelect} style={{ height: spot.height }}></div>
          <div onClick={handleSelect} style={{ width: spot.width, top: spot.height }}></div>
          <div onClick={handleSelect} style={{ height: spot.height, left: spot.width }}></div>
        </div>

        {selected && (
          <Rnd
            tabIndex={0}
            aria-label={'Selected bounding box'}
            resizeHandleWrapperClass='resizeHandles'
            position={{ x: spot.x, y: spot.y }}
            size={{ width: spot.width, height: spot.height }}
            scale={scale}
            onDragStop={handleDragStop}
            onResizeStop={handleResizeStop}
            onKeyDown={handleKeyDown}
          ></Rnd>
        )}
        <div className='resizeChildren' style={{ top: spot.y, left: spot.x }}>
          {children}
        </div>
      </>
    )
  }
)

const MultiSelectDraggableObject = observer(
  ({
    data,
    multiSelectedUID,
    onSelect,
    scale,
  }: {
    data: Section | Table
    multiSelectedUID: string[]
    onSelect: (event: any, data: any) => void
    scale: any
  }) => {
    const selectedChildren =
      data.type === 'Table'
        ? data.columns.filter((child: TableColumn) => multiSelectedUID.includes(child.id))
        : data.children.filter((child: TextField | ImageField | Table | Section) => multiSelectedUID.includes(child.id))

    // Calculate the minimum and maximum x and y coordinates
    const minX = selectedChildren.reduce((min: any, child: any) => Math.min(min, child.bbox.x), Infinity)
    const minY = selectedChildren.reduce((min: any, child: any) => Math.min(min, child.bbox.y), Infinity)
    const maxX = selectedChildren.reduce(
      (max: any, child: any) => Math.max(max, child.bbox.x + child.bbox.width),
      -Infinity
    )
    const maxY = selectedChildren.reduce(
      (max: any, child: any) => Math.max(max, child.bbox.y + child.bbox.height),
      -Infinity
    )

    // Calculate the width and height of the bounding square
    const width = maxX - minX
    const height = maxY - minY

    const handleDragStop = (e: any, d: any) => {
      e.stopPropagation()
      undoManager.startGroup(() => {
        selectedChildren.forEach((child: TextField | ImageField | Table | Section | TableColumn) => {
          child.bbox.setAll({
            ...child.bbox,
            y: Math.round(d.y - minY + child.bbox.y),
            x: Math.round(d.x - minX + child.bbox.x),
          })
        })
        undoManager.stopGroup()
      })
    }

    const handleResizeStop = (e: any, direction: any, ref: any, delta: any, position: any) => {
      e.stopPropagation()

      undoManager.startGroup(() => {
        selectedChildren.forEach((child: TextField | ImageField | Table | Section | TableColumn) => {
          child.bbox.setAll({
            y: Math.round(position.y + (child.bbox.y - minY) * ((height + delta.height) / height)),
            x: Math.round(position.x + (child.bbox.x - minX) * ((width + delta.width) / width)),
            width: Math.round(child.bbox.width * ((width + delta.width) / width)),
            height: Math.round(child.bbox.height * ((height + delta.height) / height)),
          })
        })
        undoManager.stopGroup()
      })
    }

    return (
      <>
        {selectedChildren.map((child: TextField | ImageField | Table | Section | TableColumn, index: number) => (
          <div
            className={'AreaObject'}
            key={child.id + '-2-selectedChildren-' + index}
            style={{
              width: 3 + child.bbox.width,
              height: 3 + child.bbox.height,
              top: child.bbox.y - minY,
              left: child.bbox.x - minX,
              opacity: 0.5,
            }}
          ></div>
        ))}
        <Rnd
          resizeHandleWrapperClass='resizeHandles'
          position={{ x: minX, y: minY }}
          size={{ width: width, height: height }}
          scale={scale}
          onDragStop={handleDragStop}
          onResizeStop={handleResizeStop}
        ></Rnd>
      </>
    )
  }
)

export default observer(CanvasView)
