import React, { useEffect, useState, useCallback } from 'react'
import { Button, Container, Modal, Stack, TextField, Typography } from '@mui/material'
import { useRootStore } from '../../providers/RootStoreProvider'
import { observer } from 'mobx-react-lite'
import Account from '../../domain/Account'
import { useTranslation } from 'react-i18next'

const AccountPropertiesModal = ({
  addAccountModalOpen,
  setAddAccountModalOpen,
}: {
  addAccountModalOpen: boolean
  setAddAccountModalOpen: (data: boolean) => void
}) => {
  const { t } = useTranslation()
  const { accountStore } = useRootStore()

  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)

  useEffect(() => {
    if (accountStore.currentAccount) {
      setName(accountStore.currentAccount.name || '')
      setDescription(accountStore.currentAccount.description || '')
    } else {
      setName('')
      setDescription('')
    }
  }, [accountStore.currentAccount])

  const resetForm = useCallback(() => {
    setName('')
    setDescription('')
  }, [])

  const handleSave = useCallback(() => {
    setSubmitted(true)
    if (accountStore.currentAccount) {
      accountStore.currentAccount.setName(name)
      accountStore.currentAccount.setDescription(description)
      accountStore.updateCurrent().then(() => {
        resetForm()
        setSubmitted(false)
        setAddAccountModalOpen(false)
        accountStore.clearCurrent()
      })
    } else {
      const newAccount = new Account(null)
      newAccount.setName(name)
      newAccount.setDescription(description)
      accountStore.create(newAccount).then(() => {
        resetForm()
        accountStore.loadAccounts()
        setSubmitted(false)
        setAddAccountModalOpen(false)
        accountStore.clearCurrent()
      })
    }
  }, [name, description, accountStore, resetForm, setAddAccountModalOpen])

  return (
    <Modal
      open={addAccountModalOpen}
      onClose={() => {
        setAddAccountModalOpen(false)
      }}
    >
      <Container
        maxWidth='xs'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'var(--paper-color)',
          borderRadius: 1.5,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          padding: 3,
        }}
      >
        <Typography variant='h6' component='h2' mb={2}>
          {accountStore.currentAccount
            ? t('adminAccount.accountManager.editAccount')
            : t('adminAccount.accountManager.addAccount')}
        </Typography>

        <Stack spacing={2}>
          <TextField
            label={t('adminAccount.name')}
            value={name}
            disabled={submitted}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <TextField
            label={t('adminAccount.description')}
            value={description}
            disabled={submitted}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
        </Stack>

        <Stack direction='row' justifyContent='flex-end' spacing={2} mt={2}>
          <Button color='secondary' variant='contained' disabled={submitted} onClick={handleSave}>
            {accountStore.currentAccount ? t('adminAccount.save') : t('adminAccount.add')}
          </Button>
          <Button
            variant='outlined'
            disabled={submitted}
            onClick={() => {
              setAddAccountModalOpen(false)
              accountStore.clearCurrent()
            }}
          >
            {t('adminAccount.cancel')}
          </Button>
        </Stack>
      </Container>
    </Modal>
  )
}

export default observer(AccountPropertiesModal)
