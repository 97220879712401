import { redirectToLogin } from '../utils/auth'

async function apiRequest(request: Request, responseType?: 'blob'): Promise<any> {
  try {
    const response = await fetch(request)

    if (response.status === 401) {
      // this should not happen as backend API service should always get valid token after user is logged in
      redirectToLogin()
      return
    }

    if (!response.ok) {
      const errorText = await response.text()
      let errorMessage = `HTTP ${response.status}: ${response.statusText}`
      try {
        const errorJson = JSON.parse(errorText)
        if (errorJson.message) {
          errorMessage += ` - ${errorJson.message}`
        }
      } catch (e) {
        if (errorText) {
          errorMessage += ` - ${errorText}`
        }
      }
      throw new Error(errorMessage)
    }

    if (responseType === 'blob') {
      return await response.blob()
    } else {
      const text = await response.text()
      return text ? JSON.parse(text) : {}
    }
  } catch (error) {
    console.error('API request error:', error)
    throw error
  }
}

export default apiRequest
