import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import { Box, Toolbar, Container, List, Divider, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { MainListItems } from '../components/listItems'
import { Link, Outlet } from 'react-router-dom'
import Copyright from '../components/Copyright'

import '../styles/MainLayout.css'
import logo from '../styles/images/1_2_logo_XPER_white_woBg.png'
import CustomSnackbar from '../components/CustomSnackbar'
import { useState } from 'react'
import MainLayoutHeader from '../components/MainLayoutHeader'

const drawerWidth = 200

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

export default function MainLayout() {
  const [open, setOpen] = useState(false)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CustomSnackbar />
      <Drawer variant='permanent' open={open}>
        <Divider />
        <Link to='/' style={{ textDecoration: 'none' }}>
          <div className={'logoContainer' + (open ? ' logoContainerOpen' : '')}>
            <img src={logo} className='logo' alt='Xper logo' />
          </div>
        </Link>
        <List component='nav'>
          <MainListItems />
        </List>
        <Toolbar
          sx={{
            display: 'flex',
            flex: '1',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <Tooltip title='Collapse drawer'>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </Drawer>
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[900],
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100vh',
          background: 'var(--background-color)',
          overflow: 'auto',
        }}
      >
        <MainLayoutHeader />

        <Container
          maxWidth={false}
          sx={{
            pr: '60px !important',
            pl: '60px !important',
            pt: 0,
            pb: 4,
            m: 0,
            height: '100%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Outlet />
        </Container>
        <footer>
          <Container
            maxWidth={false}
            disableGutters
            sx={{
              position: 'absolute',
              bottom: '6px',
              left: '50%',
              transform: 'translate(-50%)',
              width: 'auto',
              opacity: 0.6,
            }}
          >
            <Copyright />
          </Container>
        </footer>
      </Box>
    </Box>
  )
}
