import { makeAutoObservable } from 'mobx'

export class SampleDocument {
  id = ''
  name = ''
  content = ''

  constructor(sampleDocument: SampleDocument | null = null) {
    sampleDocument && Object.assign(this, sampleDocument)
    makeAutoObservable(this)
  }

  setId(id: string) {
    this.id = id
  }

  setName(name: string) {
    this.name = name
  }

  setContent(content: string) {
    this.content = content
  }
}
export default SampleDocument
