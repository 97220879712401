import React, { ReactNode, useEffect, useState } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import BorderAllTwoToneIcon from '@mui/icons-material/BorderAllTwoTone'
import DataArrayIcon from '@mui/icons-material/DataArray'
import DataObjectIcon from '@mui/icons-material/DataObject'
import CodeIcon from '@mui/icons-material/Code'
import AbcIcon from '@mui/icons-material/Abc'
import ImageIcon from '@mui/icons-material/Image'

import '../../styles/DOMTemplateTreeView.css'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view'
import { useRootStore } from '../../providers/RootStoreProvider'
import { observer } from 'mobx-react'

function PDFParserTreeView({
  onSelect,
  setPageNumber,
}: {
  onSelect: (data: any) => void
  setPageNumber: (data: any) => void
}) {
  const { sampleDocumentStore } = useRootStore()

  useEffect(() => {
    !sampleDocumentStore.currentParsedSampleDocument && sampleDocumentStore.parsePDF()
  }, [sampleDocumentStore.currentSampleDocument])

  const renderTree = (data: any, nodeId: string, label: string): React.ReactNode => {
    if (typeof data === 'object' && data !== null) {
      if (Array.isArray(data)) {
        return (
          <TreeItem
            key={nodeId}
            itemId={nodeId}
            label={
              <TreeLabel
                id={nodeId}
                icon={<DataArrayIcon />}
                color={'#e8a73d'}
                label={<div className='DOMLabel'>{label}</div>}
              />
            }
          >
            {data.map((item, index) => renderTree(item, `${nodeId}-${index}`, `${index + 1}`))}
          </TreeItem>
        )
      } else {
        return (
          <TreeItem
            key={nodeId}
            itemId={nodeId}
            label={
              <TreeLabel
                id={nodeId}
                icon={
                  data.type && data.type === 'Text' ? (
                    <AbcIcon />
                  ) : data.type === 'Image' ? (
                    <ImageIcon />
                  ) : (
                    <DataObjectIcon />
                  )
                }
                color={'#e8a73d'}
                label={
                  <div className='DOMLabel'>
                    {label} {data.content && <div className='DOMLabelContent'>&quot;{data.content}&quot;</div>}
                  </div>
                }
                spot={data}
                index={Number(label)}
              />
            }
          >
            {Object.keys(data as object).map((key, index) =>
              renderTree((data as { [key: string]: any })[key], `${nodeId}-${index}`, key)
            )}
          </TreeItem>
        )
      }
    } else {
      return (
        <TreeItem
          key={nodeId}
          itemId={nodeId}
          label={
            <TreeLabel
              id={nodeId}
              icon={<></>}
              color={'#e8a73d'}
              label={<div className='DOMLabel'>{`${label}: ${data}`}</div>}
            />
          }
        />
      )
    }
  }

  const TreeLabel = ({
    icon,
    color,
    spot,
    label,
    id,
    index,
  }: {
    icon: ReactNode
    color: string
    spot?: any
    label: ReactNode
    id: string
    index?: number
  }) => {
    const handleSelectLabel = () => {
      spot?.type && spot.type === 'Page' && index && setPageNumber(index)
      spot && onSelect(spot)
    }

    return (
      <div
        className={'TreeLabel'}
        style={{ color }}
        onClick={(e) => {
          e.stopPropagation()
          handleSelectLabel()
        }}
      >
        {icon}
        <div>{label}</div>
      </div>
    )
  }

  return (
    <SimpleTreeView slots={{ collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon }}>
      {typeof sampleDocumentStore.currentParsedSampleDocument === 'object' &&
        sampleDocumentStore.currentParsedSampleDocument !== null &&
        Object.keys(sampleDocumentStore.currentParsedSampleDocument as object).map((key, index) =>
          renderTree(
            (sampleDocumentStore.currentParsedSampleDocument as { [key: string]: any })[key],
            `${'root'}-${index}`,
            key
          )
        )}
    </SimpleTreeView>
  )
}

export default observer(PDFParserTreeView)
