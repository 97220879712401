import React, { useEffect, useState } from 'react'
import { Button, Skeleton, Stack, Tooltip } from '@mui/material'
import { useParams } from 'react-router'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useRootStore } from '../providers/RootStoreProvider'
import { observer } from 'mobx-react'
import AdHodPdfEngine from '../components/AdHodPdfEngine'
import SampleDocumentManager from '../components/SampleDocument/SampleDocumentManager'
import TemplatePropertiesModal from '../components/Template/TemplatePropertiesModal'
import TemplateDeleteModal from '../components/Template/TemplateDeleteModal'
import { useTranslation } from 'react-i18next'

const Template = () => {
  const { t } = useTranslation()
  const { accountId, projectId, templateId } = useParams()
  const { accountStore, projectStore, templateStore, sampleDocumentStore } = useRootStore()
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [editTemplateModalOpen, setEditTemplateModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (accountId && projectId && templateId) {
      accountStore.loadCurrent(accountId)
      projectStore.setCurrentAccountId(accountId)
      projectStore.loadCurrent(projectId)
      templateStore.setCurrentAccountId(accountId)
      templateStore.setCurrentProjectId(projectId)
      templateStore.loadCurrent(templateId)
      sampleDocumentStore.clearCurrent()
    }
  }, [accountId, projectId, templateId])

  return (
    <>
      <Stack direction={'row'} alignItems='center' justifyContent={'between'}>
        <h1>{templateStore.currentTemplate ? templateStore.currentTemplate.name : <Skeleton width={300} />}</h1>

        <Stack
          direction={'row'}
          sx={{
            ml: 'auto',
            '.MuiButton-root': {
              width: '40px',
              height: '30px',
              minWidth: '0',
            },
            '.MuiSvgIcon-root': {
              width: '20px',
              height: '20px',
            },
          }}
          spacing={0.5}
        >
          <Tooltip title={t('templates.templateProperties.editTemplate')}>
            <span>
              <Button
                color='secondary'
                variant='contained'
                onClick={(e) => {
                  setEditTemplateModalOpen(true)
                  e.stopPropagation()
                }}
                disabled={!templateStore.currentTemplate}
              >
                <EditIcon />
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={t('templates.templateProperties.deleteTemplate')}>
            <span>
              <Button
                color='error'
                variant='contained'
                onClick={(e) => {
                  e.stopPropagation()
                  setDeleteModalOpen(true)
                }}
                disabled={!templateStore.currentTemplate}
              >
                <DeleteIcon />
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Stack>

      <Stack direction={'row'} spacing={3} maxHeight={'84%'} justifyContent={'center'}>
        <SampleDocumentManager accountId={accountId} projectId={projectId} templateId={templateId} />
        <AdHodPdfEngine templateId={templateId} />
      </Stack>
      <TemplateDeleteModal deleteModalOpen={deleteModalOpen} setDeleteModalOpen={setDeleteModalOpen} />
      <TemplatePropertiesModal
        addTemplateModalOpen={editTemplateModalOpen}
        setAddTemplateModalOpen={setEditTemplateModalOpen}
      />
    </>
  )
}

export default observer(Template)
