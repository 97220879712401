import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import App from './App'
import theme from './theme/theme'
import { RootStoreProvider } from './providers/RootStoreProvider'
import './i18n'
import { setupMocks } from './mocks/setup'

const rootElement = document.getElementById('root')
const root = rootElement ? ReactDOM.createRoot(rootElement) : null

setupMocks().then(() => {
  if (root) {
    root.render(
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <RootStoreProvider>
            <App />
          </RootStoreProvider>
        </ThemeProvider>
      </React.StrictMode>
    )
  }
})
