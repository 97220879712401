import React, { useCallback, useRef } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Box, Typography } from '@mui/material'
import eventEmitter from './eventEmitter'
import { useTranslation } from 'react-i18next'

const Drop: React.FC<{ onSelectedFiles: (files: File[]) => void; disabled?: boolean }> = ({
  onSelectedFiles: onSelected,
  disabled,
}) => {
  const { t } = useTranslation()

  const onSelectedRef = useRef(onSelected)
  onSelectedRef.current = onSelected

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      const s = fileRejections.map(({ file, errors }) => {
        return file.name + '[' + file.size + 'B]: ' + errors.map((e) => e.message).join(', ')
      })

      eventEmitter.emit('showSnackbar', {
        message: s.join('\n'),
        severity: 'error',
      })
    }

    // silently ignore if nothing to do
    if (acceptedFiles.length < 1) {
      return
    }
    onSelectedRef.current(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 10,
    maxSize: 5000000,
    multiple: true,
    accept: { 'application/pdf': ['.pdf'] },
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: 'var(--light-gray-color)',
        borderRadius: 1,
        alignItems: 'center',
        justifyContent: 'left',
        p: 2,
        width: '100%',
        textTransform: 'none',
        WebkitJustifyContent: 'center',
        '&:hover': { background: '#19857b0a', cursor: 'pointer', opacity: 0.9 },
        color: disabled ? 'gray' : 'var(--secondary-color)',
        h2: { m: 0, fontSize: 26, fontWeight: 600 },
        border: 'dashed 3px #0000001f',
      }}
      {...getRootProps()}
    >
      <input aria-label={t('drop.upload')} {...getInputProps()} disabled={disabled} />
      <Typography variant='h4' component='h2' sx={{ all: 'none' }}>
        {' '}
        {isDragActive ? t('drop.drop') : t('drop.upload')}{' '}
      </Typography>
      <div>{t('drop.drag')}</div>
    </Box>
  )
}

export default Drop
