import { makeAutoObservable, runInAction } from 'mobx'
import { RootStore } from './RootStore'
import UserService from '../api/UserService'
import User from '../domain/User'
import eventEmitter from '../components/eventEmitter'
import { Role, ROLE_HIERARCHY, ROLES } from '../constants/roles'
import { Subscription, interval } from 'rxjs'

export class AuthStore {
  root: RootStore
  userService: UserService
  loggedInUser: User | null = null
  isLoading = true

  private refreshSub?: Subscription

  constructor(root: RootStore) {
    this.root = root
    this.userService = new UserService()
    makeAutoObservable(this)
    this.checkAuth()
  }

  async checkAuth() {
    try {
      runInAction(() => {
        this.isLoading = true
      })

      this.refreshSub?.unsubscribe()
      const user = await this.userService.me()

      runInAction(() => {
        this.loggedInUser = new User(user)
      })
      if (user.exp) {
        const now = Date.now()
        // min of 70% of the time before expiry or 29 minutes
        // seems like the SESSION cookie expires in 30 minutes, and cause the JWT token not to be sent to backend api server
        const delay = Math.min((1000 * user.exp - now) * 0.7, 29 * 60 * 1000)
        if (delay > 10000) {
          this.refreshSub = interval(delay).subscribe(() => {
            this.checkAuth()
          })
        }
      }
    } catch (error) {
      if (error instanceof Error && error.message.includes('HTTP 401')) {
        // If it's a 401 error, the apiRequest function will handle the redirect
        return
      }
      eventEmitter.emit('showSnackbar', {
        message: 'Could not check auth - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  clear() {
    this.loggedInUser = null
  }

  get isLoggedIn() {
    return this.loggedInUser !== null && this.loggedInUser.id !== null
  }

  hasRole(role: string) {
    if (!this.loggedInUser?.roles) return false

    // Check if the user has the role directly
    if (this.loggedInUser.roles.includes(role)) return true

    // Check if the user has any role that is hierarchically above the requested role
    for (const userRole of this.loggedInUser.roles) {
      if (this.isRoleAbove(userRole, role)) {
        return true
      }
    }
    return false
  }

  private isRoleAbove(userRole: string, targetRole: string): boolean {
    // Convert full role strings to short role names
    const shortUserRole = Object.keys(ROLES).find((key) => ROLES[key as keyof typeof ROLES] === userRole)
    const shortTargetRole = Object.keys(ROLES).find((key) => ROLES[key as keyof typeof ROLES] === targetRole)
    // If the role is not found, return false
    if (!shortUserRole || !shortTargetRole) return false

    // If the user role is not in the hierarchy, return false
    if (!(shortUserRole in ROLE_HIERARCHY)) return false

    // Check if the target role is in the subordinate roles of the user role
    const subordinateRoles = ROLE_HIERARCHY[shortUserRole as keyof typeof ROLE_HIERARCHY]
    return subordinateRoles.includes(shortTargetRole as Role)
  }

  hasAccount(accountId: string) {
    // If the user has Admin role then he has access to all accounts
    if (this.hasRole(ROLES.ADMIN)) return true
    // Otherwise check if the user is assigned to the account
    return this.loggedInUser?.accounts?.includes(accountId) ?? false
  }
}
