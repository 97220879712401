import React, { useRef } from 'react'
import { Toolbar, IconButton } from '@mui/material' // Adjust as per your actual imports
import {
  ZoomOut as ZoomOutIcon,
  ZoomIn as ZoomInIcon,
  Undo as UndoIcon,
  Redo as RedoIcon,
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
  ContentCopy as ContentCopyIcon,
  ContentPaste as ContentPasteIcon,
  ContentPasteGo as ContentPasteGoIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material' // Adjust as per your actual imports
import { handleCopy, handleDuplicate, handlePaste, handleRedo, handleUndo } from './DesignerFunctions'
import { useTranslation } from 'react-i18next'
import { useRootStore } from '../../providers/RootStoreProvider'
import { undoManager } from '../../domain/DataModelsMobx'

interface ToolbarComponentProps {
  totalPages: number
  pageNum: number
  setPageNum: (value: number) => void
  handlePageScale: (value: number) => void
  setClipBoard: React.Dispatch<React.SetStateAction<any>>
  clipBoard: any
  selectedData: any
  setSelectedData: React.Dispatch<React.SetStateAction<any>>
  multiSelectedData: any[]
  setMultiSelectedData: React.Dispatch<React.SetStateAction<any[]>>
}

const ToolbarComponent: React.FC<ToolbarComponentProps> = ({
  totalPages,
  pageNum,
  setPageNum,
  handlePageScale,
  selectedData,
  setSelectedData,
  multiSelectedData,
  setMultiSelectedData,
  setClipBoard,
  clipBoard,
}) => {
  const { t } = useTranslation()
  const { sampleDocumentStore } = useRootStore()

  const toolbarRef = useRef<HTMLDivElement>(null)
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = e
    const currentIndex = buttonRefs.current.findIndex((ref) => ref === document.activeElement)

    switch (key) {
      case 'ArrowRight':
        setNextFocus(currentIndex + 1, 'next')
        e.preventDefault()
        break
      case 'ArrowLeft':
        setNextFocus(currentIndex - 1, 'previous')
        e.preventDefault()
        break
      default:
        break
    }
  }

  const setNextFocus = (index: number, direction: 'next' | 'previous') => {
    while (index >= 0 && index < buttonRefs.current.length) {
      const buttonRef = buttonRefs.current[index]
      if (buttonRef && !buttonRef.disabled) {
        buttonRef.focus()
        break
      }

      index = direction === 'next' ? index + 1 : index - 1
    }
  }

  const generatePageButtons = () => {
    const pageButtons = []
    const minPagesToShow = 3

    if (totalPages <= minPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push({
          title: t('designer.toolBar.page') + `  ${i}`,
          onClick: () => setPageNum(i),
          text: `${i}`,
          disabled: i === pageNum,
        })
      }
    } else {
      pageButtons.push({
        title: t('designer.toolBar.page') + `  1`,
        onClick: () => setPageNum(1),
        text: `1`,
        disabled: pageNum === 1,
      })

      const startPage = Math.max(2, pageNum - 2)
      const endPage = Math.min(totalPages - 1, pageNum + 2)

      if (startPage > 2) {
        pageButtons.push({
          title: `...`,
          onClick: undefined,
          text: `...`,
          disabled: true,
        })
      }

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push({
          title: t('designer.toolBar.page') + `  ${i}`,
          onClick: () => setPageNum(i),
          text: `${i}`,
          disabled: i === pageNum,
        })
      }

      if (endPage < totalPages - 1) {
        pageButtons.push({
          title: `...`,
          onClick: undefined,
          text: `...`,
          disabled: true,
        })
      }

      pageButtons.push({
        title: t('designer.toolBar.page') + ` ${totalPages}`,
        onClick: () => setPageNum(totalPages),
        text: `${totalPages}`,
        disabled: pageNum === totalPages,
      })
    }

    return pageButtons
  }

  const pageButtons = generatePageButtons()

  return (
    <Toolbar
      role='toolbar'
      ref={toolbarRef}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      sx={{
        display: 'flex',
        background: '#ffffffde',
        alignItems: 'center',
        borderRadius: 3,
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        left: '50%',
        transform: 'translateX(-50%)',
        position: 'fixed',
        zIndex: 1,
        top: 0,
        height: '40px',
        margin: '15px',
        minHeight: 'auto !important',
        button: {
          fontSize: '0.875rem',
        },
      }}
    >
      {[
        {
          title: t('designer.toolBar.previousPage'),
          onClick: () => setPageNum(Math.max(pageNum - 1, 1)),
          icon: <NavigateBeforeIcon />,
          disabled: pageNum === 1,
        },
        ...pageButtons.map((button) => ({
          title: button.title,
          onClick: button.onClick,
          icon: <>{button.text}</>,
          disabled: button.disabled,
        })),
        {
          title: t('designer.toolBar.nextPage'),
          onClick: () => setPageNum(Math.min(pageNum + 1, totalPages)),
          icon: <NavigateNextIcon />,
          disabled: pageNum === totalPages,
        },
        {
          title: t('designer.toolBar.zoomOut'),
          onClick: () => handlePageScale(-1),
          icon: <ZoomOutIcon />,
          disabled: false,
        },
        {
          title: t('designer.toolBar.zoomIn'),
          onClick: () => handlePageScale(1),
          icon: <ZoomInIcon />,
          disabled: false,
        },
        {
          title: t('designer.toolBar.undo'),
          onClick: handleUndo,
          icon: <UndoIcon />,
          disabled: false,
        },
        {
          title: t('designer.toolBar.redo'),
          onClick: handleRedo,
          icon: <RedoIcon />,
          disabled: undoManager.canRedo,
        },
        {
          title: t('designer.toolBar.duplicate'),
          onClick: () => handleDuplicate(selectedData, setSelectedData, multiSelectedData, setMultiSelectedData),
          icon: <ContentCopyIcon />,
          disabled: !selectedData && !(multiSelectedData.length > 0),
        },
        {
          title: t('designer.toolBar.copy'),
          onClick: () => handleCopy(selectedData, multiSelectedData, setClipBoard),
          icon: <ContentPasteIcon />,
          disabled: selectedData == null && multiSelectedData.length === 0,
        },
        {
          title: t('designer.toolBar.paste'),
          onClick: () => handlePaste(selectedData, setSelectedData, multiSelectedData, setMultiSelectedData, clipBoard),
          icon: <ContentPasteGoIcon />,
          disabled: selectedData == null && multiSelectedData.length === 0,
        },
        {
          title: t('designer.toolBar.createPDF'),
          onClick: () => sampleDocumentStore.createPdf(),
          icon: <FileDownloadIcon />,
          disabled: false,
        },
      ].map((buttonProps, index) => (
        <IconButton
          key={buttonProps.title}
          title={buttonProps.title}
          onClick={buttonProps.onClick}
          disabled={sampleDocumentStore.isLoading ? true : buttonProps.disabled}
          tabIndex={-1} // Remove from tab order
          ref={(el) => (buttonRefs.current[index] = el)}
        >
          {buttonProps.icon}
        </IconButton>
      ))}
    </Toolbar>
  )
}

export default ToolbarComponent
