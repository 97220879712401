import React, { useEffect } from 'react'
import { Box, Container, Skeleton, Stack, IconButton, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router'
import Drop from '../../components/Drop'

import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone'
import ClearIcon from '@mui/icons-material/Clear'

import { useRootStore } from '../../providers/RootStoreProvider'
import SampleDocument from '../../domain/SampleDocument'
import { observer } from 'mobx-react'
import { Paper } from '../Paper'
import RowIElement from '../RowIElement'
import { useTranslation } from 'react-i18next'

type Props = {
  accountId: string | undefined
  projectId: string | undefined
  templateId: string | undefined
}

const SampleDocumentManager = ({ accountId, projectId, templateId }: Props) => {
  const { t } = useTranslation()

  const { sampleDocumentStore } = useRootStore()
  const navigate = useNavigate()

  useEffect(() => {
    if (accountId && projectId && templateId) {
      sampleDocumentStore.setCurrentAccountId(accountId)
      sampleDocumentStore.setCurrentProjectId(projectId)
      sampleDocumentStore.setCurrentTemplateId(templateId)
      sampleDocumentStore.loadSampleDocuments()
      sampleDocumentStore.clearCurrent()
    }
  }, [accountId, projectId && templateId])

  function onSelectedFiles(files: File[]): void {
    files.forEach((file) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64Data = reader.result as string
        if (accountId && projectId) {
          const newSampleDocument = new SampleDocument(null)
          newSampleDocument.setName(file.name)
          newSampleDocument.setContent(base64Data.split(',')[1])
          sampleDocumentStore.create(newSampleDocument)
        }
      }
      reader.readAsDataURL(file)
    })
  }

  return (
    <Paper skeleton={sampleDocumentStore.isLoading}>
      <Stack className='PaperHeader'>
        <h2>{t('sampleDocuments.sampleDocumentManager.templateDesigner')}</h2>
        <h3>{t('sampleDocuments.sampleDocumentManager.select')}</h3>
      </Stack>
      <Stack sx={{ alignItems: 'left', m: 2, mt: 0, overflow: 'auto' }} spacing={1}>
        {!sampleDocumentStore.isLoading ? (
          sampleDocumentStore.sampleDocuments.map((child: SampleDocument, index: number) => (
            <RowIElement
              key={'sample-' + index}
              disabled={child?.id?.includes('loading')}
              onClick={() => {
                navigate(
                  '/accounts/' +
                    accountId +
                    '/projects/' +
                    projectId +
                    '/templates/' +
                    templateId +
                    '/sampleDocuments/' +
                    child.id +
                    '/designer'
                )
              }}
              title={child.name}
              icon={
                !child?.id?.includes('loading') ? (
                  <InsertDriveFileTwoToneIcon />
                ) : (
                  <CircularProgress size={20} color='inherit' />
                )
              }
              onRemove={(e) => {
                sampleDocumentStore.delete(child)
                e.stopPropagation()
              }}
            />
          ))
        ) : (
          <>
            <Skeleton variant='rounded' sx={{ background: 'var(--light-gray-color)' }} width={'100%'} height={65} />
            <Skeleton variant='rounded' sx={{ background: 'var(--light-gray-color)' }} width={'100%'} height={65} />
          </>
        )}
      </Stack>
      <Drop onSelectedFiles={onSelectedFiles} disabled={sampleDocumentStore.isLoading} />
    </Paper>
  )
}

export default observer(SampleDocumentManager)
