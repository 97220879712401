import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react'
import { useAuthStore } from '../providers/RootStoreProvider';
import { CircularProgress, Box } from '@mui/material';
import { redirectToLogin } from '../utils/auth'

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRoles?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredRoles }) => {
  const location = useLocation();
  const authStore = useAuthStore();
  const { accountId } = useParams()

  const renderForbiddenNavigation = () => (
    <Navigate to="/forbidden" state={{ from: location }} replace />
  );

  if (authStore.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!authStore.isLoggedIn) {
    redirectToLogin()
    return null;
  }

  if (requiredRoles && requiredRoles.length > 0) {
    const hasRequiredRole = requiredRoles.some(role => authStore.hasRole(role));
    if (!hasRequiredRole) {
      return renderForbiddenNavigation();
    }
  }

  if (accountId && !authStore.hasAccount(accountId)) {
    return renderForbiddenNavigation();
  }

  return <>{children}</>;
};

export default observer(ProtectedRoute);
