import eventEmitter from '../eventEmitter'
import {
  DOMTemplate,
  Document as DOMDocument,
  ImageField,
  Section,
  Spot,
  TableColumn,
  Table,
  TextField,
  undoManager,
  DocumentInfo,
  List,
} from '../../domain/DataModelsMobx'
import { getParent } from 'mobx-state-tree'

type AllTypes = Spot | DOMDocument | Section | TextField | ImageField | Table | TableColumn | List

export const handleCopy = (
  selectedData: DocumentInfo | AllTypes | null,
  multiSelectedData: AllTypes[],
  setClipBoard: (data: AllTypes[]) => void
) => {
  setClipBoard(
    selectedData ? [selectedData as AllTypes] : multiSelectedData ? multiSelectedData.slice(1, undefined) : []
  )
  eventEmitter.emit('showSnackbar', {
    message: 'Copied to clipboard',
    severity: 'success',
  })
}

export const handlePaste = (
  selectedData:
    | DocumentInfo
    | DOMDocument
    | Spot
    | Section
    | TextField
    | ImageField
    | Table
    | TableColumn
    | List
    | null,
  setSelectedData: (data: DocumentInfo | AllTypes | null) => void,
  multiSelectedData: AllTypes[],
  setMultiSelectedData: (data: AllTypes[]) => void,
  clipBoard: AllTypes[]
) => {
  const clipBoardParent = getParent<Section | Table | DOMDocument>(clipBoard[0], 2)

  if (!selectedData) {
    eventEmitter.emit('showSnackbar', {
      message: 'No target selected for pasting.',
      severity: 'error',
    })
    return
  }

  if (
    (selectedData.type === 'Document' || 'Section' || 'Table') &&
    clipBoard.length > 0 &&
    (selectedData.type === clipBoardParent.type ||
      (selectedData.type === 'Document' && clipBoardParent.type === 'Section'))
  ) {
    if (clipBoard.some((item) => item.id === selectedData.id)) {
      eventEmitter.emit('showSnackbar', {
        message: 'Failed to paste clipboard. Pasting will result in infinite loop.',
        severity: 'error',
      })
      return
    }
    const newChild = (selectedData as DOMDocument | Section | Table).clone(clipBoard, ' Copy')
    if (newChild.length == 1) {
      setSelectedData(newChild[0])
    } else {
      setSelectedData(null)
      setMultiSelectedData([selectedData, ...newChild])
    }
    eventEmitter.emit('showSnackbar', {
      message: 'Pasted clipboard',
      severity: 'success',
    })
  } else {
    eventEmitter.emit('showSnackbar', {
      message: 'Failed to paste clipboard. Can only paste in parent.',
      severity: 'error',
    })
  }
}

export const handleUndo = () => {
  // make sure deleted child in selects parent after
  if (undoManager.canUndo) {
    undoManager.undo()
  } else {
    eventEmitter.emit('showSnackbar', {
      message: 'Could not undo',
      severity: 'error',
    })
  }
}

export const handleRedo = () => {
  if (undoManager.canRedo) {
    undoManager.redo()
  } else {
    eventEmitter.emit('showSnackbar', {
      message: 'Could not redo',
      severity: 'error',
    })
  }
}

export const handleDuplicate = (
  selectedData:
    | DocumentInfo
    | DOMDocument
    | Spot
    | Section
    | TextField
    | ImageField
    | Table
    | TableColumn
    | List
    | null,
  setSelectedData: (data: DocumentInfo | AllTypes | null) => void,
  multiSelectedData: AllTypes[],
  setMultiSelectedData: (data: AllTypes[]) => void
) => {
  if (selectedData) {
    const newChild = getParent<Section | DOMDocument | Table>(selectedData, 2).clone([selectedData], ' Copy')
    setSelectedData(newChild[0])
  } else {
    const newChild = (multiSelectedData[0] as Section | DOMDocument | Table).clone(multiSelectedData.slice(1), ' Copy')
    setMultiSelectedData([multiSelectedData[0], ...newChild])
  }
}

export const handleDelete = (
  selectedData:
    | DocumentInfo
    | DOMDocument
    | Spot
    | Section
    | TextField
    | ImageField
    | Table
    | TableColumn
    | List
    | null,
  setSelectedData: (data: DocumentInfo | AllTypes | null) => void,
  multiSelectedData?: AllTypes[],
  setMultiSelectedData?: (data: AllTypes[]) => void
) => {
  if (selectedData) {
    const parent: Section | DOMDocument | Table | DOMTemplate = getParent<Section | DOMDocument | Table | DOMTemplate>(
      selectedData,
      2
    )
    const parentArray = getParent<any[]>(selectedData)
    const index = parentArray.indexOf(selectedData)

    if (parent.type == 'Section') {
      ;(parent as Section).deleteChild(index)
      setSelectedData(parent as Section)
    } else if (parent.type == 'Document') {
      ;(parent as DOMDocument).deleteSection(index)
      setSelectedData(parent as DOMDocument)
    } else if (parent.type == 'DOMTemplate') {
      if (selectedData.type === 'TextSpot' || selectedData.type === 'ImageSpot') {
        ;(parent as DOMTemplate).deleteSpot(index)
      } else if (selectedData.type === 'Document') {
        ;(parent as DOMTemplate).deleteDocument(index)
      }
      setSelectedData(null)
    } else if (parent.type == 'Table') {
      ;(parent as Table).deleteColumn(index)
      setSelectedData(parent as Table)
    }
  } else if (multiSelectedData) {
    const parent: Section | DOMDocument | Table = getParent<Section | DOMDocument | Table>(multiSelectedData[1], 2)
    const parentArray = getParent<any[]>(multiSelectedData[1])
    for (let i = 1; i < multiSelectedData.length; i++) {
      const index = parentArray.indexOf(multiSelectedData[i])

      if (parent.type == 'Section') {
        ;(parent as Section).deleteChild(index)
      } else if (parent.type == 'Document') {
        ;(parent as DOMDocument).deleteSection(index)
      } else if (parent.type == 'Table') {
        ;(parent as Table).deleteColumn(index)
      }
    }
    setMultiSelectedData && setMultiSelectedData([])
    setSelectedData(parent)
  }
}

export const handleSelectData = (
  selectedData:
    | DocumentInfo
    | DOMDocument
    | Spot
    | Section
    | TextField
    | ImageField
    | Table
    | TableColumn
    | List
    | null,
  setSelectedData: (data: DocumentInfo | AllTypes | null) => void,
  multiSelectedData: AllTypes[],
  setMultiSelectedData: (data: AllTypes[]) => void
) => {
  return (event: any, data: AllTypes | null) => {
    if (event && event.ctrlKey) {
      const parent = getParent<Section | DOMDocument | Table>(data, 2)
      if (multiSelectedData.length === 0 && selectedData && getParent(selectedData, 2) === parent) {
        data && setMultiSelectedData([parent, selectedData as AllTypes, data])
        setSelectedData(null)
      } else if (multiSelectedData && multiSelectedData[0] === parent) {
        data && setMultiSelectedData([...multiSelectedData, data])
      } else {
        eventEmitter.emit('showSnackbar', {
          message: 'You can only multi select objects of same parent',
          severity: 'error',
        })
      }
    } else if (event && event.shiftKey) {
      const parent = getParent<Section | DOMDocument | Table>(data, 2)
      const parentArray = getParent<any[]>(data)
      if (multiSelectedData.length === 0 && selectedData && getParent(selectedData, 2) === parent) {
        if (parentArray.indexOf(selectedData) < parentArray.indexOf(data)) {
          data &&
            setMultiSelectedData([
              parent,
              ...parentArray.slice(parentArray.indexOf(selectedData), parentArray.indexOf(data) + 1),
            ])
        } else {
          data &&
            setMultiSelectedData([
              parent,
              ...parentArray.slice(parentArray.indexOf(data), parentArray.indexOf(selectedData) + 1),
            ])
        }
        setSelectedData(null)
      } else if (multiSelectedData && multiSelectedData[0] === parent) {
        if (parentArray.indexOf(multiSelectedData[1]) < parentArray.indexOf(data)) {
          data &&
            setMultiSelectedData([
              parent,
              ...parentArray.slice(parentArray.indexOf(multiSelectedData[1]), parentArray.indexOf(data) + 1),
            ])
        } else {
          data &&
            setMultiSelectedData([
              parent,
              ...parentArray.slice(
                parentArray.indexOf(data),
                parentArray.indexOf(multiSelectedData[multiSelectedData.length - 1]) + 1
              ),
            ])
        }
      } else {
        eventEmitter.emit('showSnackbar', {
          message: 'You can only multi select objects of same parent',
          severity: 'error',
        })
      }
    } else {
      setSelectedData(data)
      setMultiSelectedData([])
    }
  }
}

export function getAllIds(item: AllTypes | DOMTemplate): string[] {
  if (item.type === 'DOMTemplate') {
    return ['DOMTemplate', ...(item as DOMTemplate).documents.flatMap((childNode: AllTypes) => getAllIds(childNode))]
  } else {
    return [
      (item as AllTypes).id,
      ...(item.type === 'Document'
        ? (item as DOMDocument).sections
        : item.type === 'Section'
          ? (item as Section).children
          : []
      ).flatMap((childNode: AllTypes) => getAllIds(childNode)),
    ]
  }
}

export function getAllIdsDomResult(obj: any, path: string): string[] {
  return [
    path,
    ...(obj.children
      ? obj.children.flatMap((childNode: any, childIndex: number) =>
          getAllIdsDomResult(childNode, path + '-children-' + childIndex)
        )
      : []),
  ]
}
