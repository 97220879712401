import React, { forwardRef, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import SettingsIcon from '@mui/icons-material/Settings'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, IconButton, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface PanelBoxProps {
  title: string | React.ReactNode
  children?: React.ReactNode
  onClose?: (data: any) => void
  onSettings?: (data: any) => void
  loading?: boolean
  minimize?: boolean
}

const PanelBox = forwardRef<HTMLDivElement, PanelBoxProps>(
  ({ title, children, onClose, onSettings, loading, minimize }, ref) => {
    const { t } = useTranslation()
    const [isMinimized, setIsMinimized] = useState<boolean>()

    return (
      <>
        {loading ? (
          <Skeleton
            variant='rounded'
            animation='pulse'
            sx={{
              width: 'calc(100% - 20px)',
              height: 'calc(100% - 20px)',
              minHeight: 100,
              m: 1,
              p: '5px',
              borderRadius: '5px',
              background: 'var(--paper-color)',
              opacity: 0.43,
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
            }}
          />
        ) : (
          <Stack
            role='region'
            sx={{
              width: 'calc(100% - 20px)',
              maxHeight: 'calc(100% - 20px)',
              m: 1,
              p: '5px',
              borderRadius: '5px',
              background: 'var(--paper-color)',
              opacity: 0.97,
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
              minHeight: 60,
            }}
          >
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              pl={1.5}
              fontWeight={'bold'}
              textTransform={'capitalize'}
            >
              <Typography variant='h1' sx={{ all: 'unset' }}>
                {title}
              </Typography>
              <Stack direction={'row'} spacing={-1}>
                {minimize && (
                  <Tooltip
                    title={
                      (isMinimized ? t('designer.propertiesView.show') : t('designer.propertiesView.hide')) +
                      ' ' +
                      (typeof title === 'string' ? title : '')
                    }
                  >
                    <IconButton onClick={() => setIsMinimized(!isMinimized)}>
                      {!isMinimized ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                  </Tooltip>
                )}
                {onClose && (
                  <Tooltip title={'Close ' + title}>
                    <IconButton onClick={onClose}>
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {onSettings && (
                  <Tooltip title={'Document settings'}>
                    <IconButton onClick={onSettings}>
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
            {!isMinimized && (
              <Box
                sx={{
                  overflowY: 'auto !important',
                  overflow: 'hidden',
                }}
              >
                {children}
              </Box>
            )}
          </Stack>
        )}
      </>
    )
  }
)

PanelBox.displayName = 'PanelBox'

export default PanelBox
