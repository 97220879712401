import React, { ReactNode } from 'react'
import { Container, SxProps, Theme } from '@mui/material'

export const Paper = ({
  children,
  sx,
  skeleton,
}: {
  children?: ReactNode
  sx?: SxProps<Theme>
  skeleton?: boolean
}) => {
  return (
    <Container
      maxWidth='sm'
      sx={{
        background: 'var(--paper-color)',
        borderRadius: 1.5,
        pb: '24px',
        height: 'fit-content',
        maxHeight: '100%',
        minHeight: 500,
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        display: 'flex',
        flexDirection: 'column',
        ...sx,
        '.PaperHeader': {
          m: '20px 0',
          alignItems: 'left',
          h2: {
            color: skeleton ? 'gray' : 'var(--text-color)',
            margin: 0,
          },
          h3: {
            color: skeleton ? 'gray' : 'var(--text-color)',
            margin: 0,
            fontWeight: 400,
            fontSize: '1rem',
          },
        },

        h4: {
          fontSize: '1.17em',
        },
        '.MuiButton-root ': { borderRadius: 4, textTransform: 'none' },
      }}
    >
      {children}
    </Container>
  )
}
