import { makeAutoObservable } from 'mobx'

export class AdHocPdf {
  id = ''
  templateId = ''
  name = ''
  content = ''
  blob?: Blob
  status = 'loading'
  checked = true
  error = ''

  constructor(adHodPdf: AdHocPdf | null = null) {
    adHodPdf && Object.assign(this, adHodPdf)
    makeAutoObservable(this)
  }

  setName(name: string) {
    this.name = name
  }
  setTemplateId(templateId: string) {
    this.templateId = templateId
  }

  setBlob(blob: Blob) {
    this.blob = blob
  }

  setStatus(status: string) {
    this.status = status
  }

  setContent(content: string) {
    this.content = content
  }

  toggleCheck() {
    this.checked = !this.checked
  }

  setCheck(check: boolean) {
    this.checked = check
  }

  setError(error: string) {
    this.error = error
  }
}
export default AdHocPdf
