import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'
import '../styles/MainLayout.css'

const cssVar = (name: string) => getComputedStyle(document.documentElement).getPropertyValue(name).trim()

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: cssVar('--primary-color'),
    },
    secondary: {
      main: cssVar('--secondary-color'),
    },
    success: {
      main: cssVar('--success-color'),
    },
    error: {
      main: cssVar('--error-color'),
    },
    info: {
      main: cssVar('--info-color'),
    },
  },
})

export default theme
