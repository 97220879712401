import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { RootStore } from './RootStore'
import UserService from '../api/UserService'
import User from '../domain/User'
import eventEmitter from '../components/eventEmitter'

export class UserStore {
  root: RootStore
  userService: UserService
  currentUser: User | null = null
  users: User[] = []
  isLoading = true

  constructor(root: RootStore) {
    this.root = root
    this.userService = new UserService()
    makeAutoObservable(this)
  }

  async loadUsers() {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const users = await this.userService.list()
      runInAction(() => {
        this.users = users.map((t) => new User(t))
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load users - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  async loadCurrent(userId: string) {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const user = await this.userService.get(userId)
      runInAction(() => {
        this.currentUser = new User(user)
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load current - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  clearCurrent() {
    this.currentUser = null
  }

  async updateCurrent() {
    if (!this.currentUser) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update current - No user selected',
        severity: 'error',
      })
      return
    }
    try {
      await this.userService.put(this.currentUser)
      // update user in array of users
      const userIndex = this.users.findIndex((t) => t.id === this.currentUser?.id)
      runInAction(() => {
        if (userIndex != -1 && this.currentUser) {
          this.users[userIndex] = this.currentUser
        }
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update current - ' + error,
        severity: 'error',
      })
    }
  }

  delete = async (user: User) => {
    try {
      // await this.userService.delete(user.id)
      runInAction(() => {
        this.users = this.users.filter((t) => t.id !== user.id)
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not delete current - ' + error,
        severity: 'error',
      })
    }
  }

  async create(user: User): Promise<User> {
    try {
      // const newUser: User = new User()
      // const newUser: User = await this.userService.post(user)
      // runInAction(() => {
      // })
      this.users.push(user)

      return user
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not create - ' + error,
        severity: 'error',
      })
      return Promise.reject(error)
    }
  }
}
