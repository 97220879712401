export function stringAvatar(name: string) {
  const initials = name
    .split(' ')
    .map((word) => word[0])
    .join('')
  return {
    sx: {
      bgcolor: stringToColor(name),
      fontWeight: 600,
      width: 35,
      height: 35,
      ':hover': {
        opacity: 0.9,
        cursor: 'pointer',
      },
    },
    children: initials,
  }
}
export function stringToColor(string: string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    let value = (hash >> (i * 8)) & 0xff
    value = Math.floor(value / 1.2) // Adjust the value to make it generally darker
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}
