import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { TableCell, TableHead, TableRow, Autocomplete, Stack, TextField } from '@mui/material'

import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { StatusCode } from '../domain/Transaction'
import { TransactionHeadCell } from './TransactionTable'
import { GetTransactionOptions } from '../api/TransactionService'

dayjs.locale('nb')
dayjs.locale('en')

dayjs.extend(utc)
dayjs.extend(timezone)

interface TransactionTableFilterProps {
  setFilter: (data: GetTransactionOptions) => void
  admin?: boolean
  headCells: TransactionHeadCell[]
}
function TransactionTableFilter(props: TransactionTableFilterProps) {
  const { i18n } = useTranslation()
  const { setFilter, admin } = props

  const [idFilter, setIdFilter] = React.useState('')
  const [accountFilter, setAccountFilter] = React.useState('')
  const [projectFilter, setProjectFilter] = React.useState('')
  const [templateFilter, setTemplateFilter] = React.useState('')
  const [dateFrom, setDateFrom] = React.useState<Dayjs | null>(null)
  const [dateTo, setDateTo] = React.useState<Dayjs | null>(null)
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null)

  useEffect(() => {
    setFilter({
      ...(idFilter && { transactionId: idFilter }),
      ...(accountFilter && { accountName: accountFilter }),
      ...(projectFilter && { projectName: projectFilter }),
      ...(templateFilter && { templateName: templateFilter }),
      ...(statusFilter && { statusCode: statusFilter }),
      ...(dateFrom && { startDate: dateFrom.format() }),
      ...(dateTo && { endDate: dateTo.format() }),
    })
  }, [idFilter, accountFilter, projectFilter, templateFilter, dateFrom, dateTo, statusFilter])

  return (
    <TableHead>
      <TableRow
        sx={{
          '.MuiInputBase-input': {
            fontSize: '.875rem',
            height: '10px',
            minHeight: '10px',
            boxSizing: 'border-box',
          },
          '.MuiSelect-select': {
            minHeight: '33px',
            padding: '7px 10px',
            minWidth: 100,
          },
          label: {
            fontSize: '.875rem',
            top: '-8px',
          },
        }}
      >
        <TableCell padding='none' sx={{ width: '10px' }} component={'td'} />
        <TableCell align='left'>
          <TextField label='Id filter' value={idFilter} onChange={(e) => setIdFilter(e.target.value)} />
        </TableCell>
        {admin && (
          <TableCell align='left'>
            <TextField
              label='Account filter'
              value={accountFilter}
              onChange={(e) => setAccountFilter(e.target.value)}
            />
          </TableCell>
        )}
        <TableCell align='left'>
          <TextField label='Project filter' value={projectFilter} onChange={(e) => setProjectFilter(e.target.value)} />
        </TableCell>
        <TableCell align='left'>
          <TextField
            label='Template filter'
            value={templateFilter}
            onChange={(e) => setTemplateFilter(e.target.value)}
          />
        </TableCell>
        <TableCell align='right' sx={{ display: 'flex' }}>
          <Stack direction={'row'} justifyContent={'end'} spacing={0.5} maxWidth={230}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'nb'}>
              <DateTimePicker
                label='Date from'
                value={dateFrom}
                onChange={(newValue) => setDateFrom(dayjs(newValue))}
                ampm={!(i18n.language === 'no')}
              />
              <DateTimePicker
                label='Date to'
                value={dateTo}
                onChange={(newValue) => setDateTo(dayjs(newValue))}
                ampm={!(i18n.language === 'no')}
              />
            </LocalizationProvider>
          </Stack>
        </TableCell>
        <TableCell align='center'>
          <Autocomplete
            renderInput={(params) => <TextField {...params} value={statusFilter} label='Status' />}
            onChange={(e, n: StatusCode | null) => setStatusFilter(n)}
            options={Object.values(StatusCode)}
          ></Autocomplete>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default observer(TransactionTableFilter)
