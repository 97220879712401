import { TransactionResponse } from '../domain/Transaction'
import apiRequest from './ApiRequest'

const webApiUrl = '/bff/api/'

export type GetTransactionOptions = {
  transactionId?: string
  accountId?: string
  accountName?: string
  projectName?: string
  templateName?: string
  startDate?: string
  endDate?: string
  page?: number
  size?: number
  sort?: string
  statusCode?: string
}

export const getTransactions = async (options: GetTransactionOptions): Promise<TransactionResponse> => {
  // Default values if not provided
  const {
    transactionId,
    accountId,
    accountName,
    projectName,
    templateName,
    startDate,
    endDate,
    page,
    size,
    sort,
    statusCode,
  } = options

  // Construct query parameters
  const queryParams = new URLSearchParams()

  if (transactionId) queryParams.append('transactionId', transactionId)
  if (accountId) queryParams.append('accountId', accountId)
  if (accountName) queryParams.append('accountName', accountName)
  if (projectName) queryParams.append('projectName', projectName)
  if (templateName) queryParams.append('templateName', templateName)
  if (startDate) queryParams.append('startDate', startDate)
  if (endDate) queryParams.append('endDate', endDate)
  if (page !== undefined) queryParams.append('page', page.toString())
  if (size !== undefined) queryParams.append('size', size.toString())
  if (sort) queryParams.append('sort', sort)
  if (statusCode) queryParams.append('statusCode', statusCode)

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }
  const request = new Request(webApiUrl + `transactions?${queryParams}`, requestOptions)
  return await apiRequest(request)
}
