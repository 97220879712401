import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export default function ContextMenu({ children, content }: { content: React.ReactNode; children?: React.ReactNode }) {
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number
    mouseY: number
  } | null>(null)

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault()
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    )
  }

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation()
    setContextMenu(null)
  }

  return (
    <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu', width: '100%' }}>
      {React.Children.map(content, (child) => child)}
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        {React.Children.map(children, (child) =>
          React.isValidElement(child) ? (
            // Ensure onSelect is a function before calling it
            <MenuItem
              onClick={(e) => {
                handleClose(e)
                child.props.onSelect && child.props.onSelect()
              }}
            >
              {child.props.children}
            </MenuItem>
          ) : null
        )}
      </Menu>
    </div>
  )
}

export interface ContextMenuItemProps {
  onSelect: (...args: any[]) => void
  children?: React.ReactNode
}
export function ContextMenuItem({ children, onSelect }: ContextMenuItemProps) {
  return <div onClick={onSelect}>{children}</div>
}
