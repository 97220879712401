import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import { Button, Container, Modal, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRootStore } from '../../providers/RootStoreProvider'
import { useNavigate, useParams } from 'react-router-dom'

interface AccountDeleteModalProps {
  deleteModalOpen: boolean
  setDeleteModalOpen: (data: boolean) => void
}

const AccountDeleteModal: React.FC<AccountDeleteModalProps> = ({ deleteModalOpen, setDeleteModalOpen }) => {
  const { t } = useTranslation()
  const { accountStore } = useRootStore()
  const { accountId, projectId } = useParams<{ accountId: string; projectId: string }>()
  const navigate = useNavigate()
  const [deleteSubmitted, setDeleteSubmitted] = useState<boolean>(false)

  const handleClose = useCallback(() => {
    setDeleteModalOpen(false)
    accountStore.clearCurrent()
  }, [setDeleteModalOpen])

  const handleDelete = useCallback(async () => {
    if (!accountStore.currentAccount) return

    setDeleteSubmitted(true)

    try {
      await accountStore.delete(accountStore.currentAccount)
      accountStore.clearCurrent()
      handleClose()
    } finally {
      setDeleteSubmitted(false)
    }
  }, [accountStore, accountId, projectId, navigate, handleClose])

  return (
    <Modal open={deleteModalOpen} onClose={handleClose}>
      <Container
        maxWidth='xs'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'var(--paper-color)',
          borderRadius: 1.5,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          padding: 3,
        }}
      >
        <Stack alignItems='center' textAlign='center'>
          <Typography variant='h6' gutterBottom>
            {t('account.delete.delete')}
          </Typography>
        </Stack>

        <Stack direction='row' justifyContent='center' spacing={2} mt={2}>
          <Button color='error' variant='contained' disabled={deleteSubmitted} onClick={handleDelete}>
            {t('account.delete.continue')}
          </Button>
          <Button variant='outlined' disabled={deleteSubmitted} onClick={handleClose}>
            {t('account.delete.cancel')}
          </Button>
        </Stack>
      </Container>
    </Modal>
  )
}

export default observer(AccountDeleteModal)
