import React, { useEffect, useState, useCallback } from 'react'
import { Button, Container, Modal, Stack, TextField, Typography } from '@mui/material'
import { useRootStore } from '../../providers/RootStoreProvider'
import { observer } from 'mobx-react-lite'
import Template from '../../domain/Template'
import { useTranslation } from 'react-i18next'

const TemplatePropertiesModal = ({
  addTemplateModalOpen,
  setAddTemplateModalOpen,
}: {
  addTemplateModalOpen: boolean
  setAddTemplateModalOpen: (data: boolean) => void
}) => {
  const { t } = useTranslation()
  const { templateStore } = useRootStore()

  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)

  useEffect(() => {
    if (templateStore.currentTemplate) {
      setName(templateStore.currentTemplate.name || '')
      setDescription(templateStore.currentTemplate.description || '')
    } else {
      setName('')
      setDescription('')
    }
  }, [templateStore.currentTemplate])

  const resetForm = useCallback(() => {
    setName('')
    setDescription('')
  }, [])

  const handleSave = useCallback(() => {
    setSubmitted(true)
    if (templateStore.currentTemplate) {
      templateStore.currentTemplate.setName(name)
      templateStore.currentTemplate.setDescription(description)
      templateStore.updateCurrent().then(() => {
        resetForm()
        setSubmitted(false)
        setAddTemplateModalOpen(false)
      })
    } else {
      const newTemplate = new Template(null)
      newTemplate.setName(name)
      newTemplate.setDescription(description)
      templateStore.create(newTemplate).then(() => {
        resetForm()
        templateStore.loadTemplates()
        setSubmitted(false)
        setAddTemplateModalOpen(false)
      })
    }
  }, [name, description, templateStore, resetForm, setAddTemplateModalOpen])

  return (
    <Modal
      open={addTemplateModalOpen}
      onClose={() => {
        setAddTemplateModalOpen(false)
      }}
    >
      <Container
        maxWidth='xs'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'var(--paper-color)',
          borderRadius: 1.5,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          padding: 3,
        }}
      >
        <Typography variant='h6' component='h2' mb={2}>
          {templateStore.currentTemplate
            ? t('templates.templateProperties.editTemplate')
            : t('templates.templateProperties.addTemplate')}
        </Typography>

        <Stack spacing={2}>
          <TextField
            label={t('templates.templateProperties.name')}
            value={name}
            disabled={submitted}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <TextField
            label={t('templates.templateProperties.description')}
            value={description}
            disabled={submitted}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
        </Stack>

        <Stack direction='row' justifyContent='flex-end' spacing={2} mt={2}>
          <Button color='secondary' variant='contained' disabled={submitted} onClick={handleSave}>
            {templateStore.currentTemplate
              ? t('templates.templateProperties.save')
              : t('templates.templateProperties.add')}
          </Button>
          <Button variant='outlined' disabled={submitted} onClick={() => setAddTemplateModalOpen(false)}>
            {t('templates.templateProperties.cancel')}
          </Button>
        </Stack>
      </Container>
    </Modal>
  )
}

export default observer(TemplatePropertiesModal)
