import { ROLES } from '../constants/roles'
import { IUser } from '../domain/User'

export const unauthorizedUser: IUser = {
  id: null,
  username: null,
  email: null,
  firstName: null,
  lastName: null,
  roles: null,
  accounts: null,
  exp: null,
  jobTitle: null,
  profilePicture: null,
  language: null,
}

export const adminUser: IUser = {
  id: '5830e7d1-f7ef-48fa-ad87-9357d3c8c231',
  username: 'SlZXoNiWS-Nw1NqKMsZCAG0PO1NDtzk5-miA9YKLdjU',
  email: 'admin@xperdocengine.onmicrosoft.com',
  firstName: 'admin',
  lastName: '',
  roles: ['SCOPE_xperdocengine', ROLES.ADMIN],
  exp: 1727852191,
  jobTitle: null,
  profilePicture: null,
  language: null,
  accounts: [],
}

export const accountManagerUser: IUser = {
  id: '66383b3c-dcb1-442b-b8b8-eabf69e50a11',
  username: 'DD3rTc3HYR56C4LQrG5NmzzucpJU6__7DhZnSGiKUnA',
  email: 'am@xperdocengine.onmicrosoft.com',
  firstName: 'Account',
  lastName: 'Manager',
  roles: ['SCOPE_xperdocengine', ROLES.ACCOUNT_MANAGER],
  accounts: ['057c38e2-6360-4998-ae79-1a89efdaebe7'],
  exp: 1727852191,
  jobTitle: null,
  profilePicture: null,
  language: null,
}

export const projectManagerUser: IUser = {
  id: '94c6d03a-220e-4360-bdf2-c7ea9b9ca9a9',
  username: 'Khgd6Hnjd5Eg_AZKZvRZm_akivQ0P1Wy2cj7Nz_18EY',
  email: 'pm@xperdocengine.onmicrosoft.com',
  firstName: 'Project',
  lastName: 'Manager',
  roles: ['SCOPE_xperdocengine', ROLES.PROJECT_MANAGER],
  accounts: ['057c38e2-6360-4998-ae79-1a89efdaebe7'],
  exp: 1727852191,
  jobTitle: null,
  profilePicture: null,
  language: null,
}

export const templateDesignerUser: IUser = {
  id: '80cfe513-932a-40a4-90d7-a7385bc5f2cb',
  username: '70qtz0GvloouO61AFpaDm8EYXTJcOPOdCr6RtyJsEY8',
  email: 'td@xperdocengine.onmicrosoft.com',
  firstName: 'Template',
  lastName: 'Designer',
  roles: ['SCOPE_xperdocengine', ROLES.TEMPLATE_DESIGNER],
  accounts: ['057c38e2-6360-4998-ae79-1a89efdaebe7'],
  exp: 1727852191,
  jobTitle: null,
  profilePicture: null,
  language: null,
}
