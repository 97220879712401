import * as React from 'react'
import { ReactNode, useEffect } from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  IconButton,
  Chip,
  Collapse,
  Skeleton,
  Stack,
  ThemeProvider,
  createTheme,
  Link,
} from '@mui/material'
import { KeyboardArrowUp as KeyboardArrowUpIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import { visuallyHidden } from '@mui/utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/en' // Change the locale if necessary

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useRootStore } from '../providers/RootStoreProvider'
import Transaction, { StatusCode, TransactionResponse } from '../domain/Transaction'
import { Paper } from './Paper'
import TransactionTableFilter from './TransactionTableFilter'
import { GetTransactionOptions } from '../api/TransactionService'
import { debounce } from 'lodash'

dayjs.extend(utc)
dayjs.extend(timezone)

type Order = 'asc' | 'desc'

export interface TransactionHeadCell {
  id: keyof Transaction
  align: 'left' | 'right' | 'center' | 'inherit' | 'justify' | undefined
  label: string
}

const headCells: TransactionHeadCell[] = [
  {
    id: 'id',
    align: 'left',
    label: 'id',
  },
  {
    id: 'projectId',
    align: 'left',
    label: 'project',
  },
  {
    id: 'templateId',
    align: 'left',
    label: 'template',
  },
  {
    id: 'creationTime',
    align: 'right',
    label: 'creationTime',
  },
  {
    id: 'statusCode',
    align: 'center',
    label: 'Status Code',
  },
]

interface EnhancedTableProps {
  headCells: TransactionHeadCell[]
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Transaction) => void
  order: Order
  orderBy: string
  rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props
  const { t } = useTranslation()

  const createSortHandler = (property: keyof Transaction) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead
      sx={{
        th: {
          whiteSpace: 'nowrap',
        },
      }}
    >
      <TableRow>
        <TableCell sx={{ width: '34px' }} padding={'none'} component={'td'} />
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t('transactions.transactionTable.' + headCell.id)}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const LinkRouter: React.FC<{
  to: string
  children: ReactNode
}> = ({ to, children }) => {
  return (
    <Link component={RouterLink} to={to} underline='hover' color='inherit'>
      {children}
    </Link>
  )
}

interface EnhancedRowProps {
  row: Transaction
  admin?: boolean
}
function EnchancedRow(props: EnhancedRowProps) {
  const { t, i18n } = useTranslation()
  const { row, admin } = props
  const [open, setOpen] = React.useState(false)

  const statusToColor: {
    [key in StatusCode]: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  } = {
    [StatusCode.WARNING]: 'warning',
    [StatusCode.ERROR]: 'error',
    [StatusCode.OK]: 'success',
  }
  return (
    <>
      <TableRow
        hover
        onClick={() => setOpen(!open)}
        sx={{
          cursor: 'pointer',
          td: {
            whiteSpace: 'nowrap',
          },
        }}
      >
        <TableCell sx={{ width: '10px' }} padding={'none'}>
          <IconButton aria-label='expand row' size='small'>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope='row'>{row.id}</TableCell>

        {admin && (
          <TableCell align='left'>
            <LinkRouter to={`/accounts/${row.accountId}/home`}>{row.accountName}</LinkRouter>
          </TableCell>
        )}
        <TableCell align='left'>
          <LinkRouter to={`/accounts/${row.accountId}/projects/${row.projectId}`}>{row.projectName}</LinkRouter>
        </TableCell>
        <TableCell align='left'>
          <LinkRouter to={`/accounts/${row.accountId}/projects/${row.projectId}/templates/${row.templateId}`}>
            {row.templateName}
          </LinkRouter>
        </TableCell>
        <TableCell align='right' sx={{ maxWidth: 'fit-content !important', input: { fontSize: '0.875rem', p: '5px' } }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {i18n.language === 'no'
              ? dayjs(row.creationTime).tz('Europe/Oslo').format('DD-MM-YYYY HH:mm:ss')
              : dayjs(row.creationTime).format('YYYY-MM-DD HH:mm:ss')}
          </LocalizationProvider>
        </TableCell>
        <TableCell align='center'>
          <Chip
            color={statusToColor[row.statusCode]}
            sx={{ opacity: 0.9 }}
            label={t('transactions.transactionTable.' + row.statusCode)}
          />
        </TableCell>
      </TableRow>
      <TableRow sx={{ background: 'rgba(0, 0, 0, 0.02)' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label='Transaction expanded'>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('transactions.transactionTable.sourceIp')}</TableCell>
                    <TableCell>{t('transactions.transactionTable.engineVersion')}</TableCell>
                    <TableCell>{t('transactions.transactionTable.processingTime')}</TableCell>
                    <TableCell>{t('transactions.transactionTable.numberOfPages')}</TableCell>
                    <TableCell>{t('transactions.transactionTable.processedBytes')}</TableCell>
                    <TableCell>{t('transactions.transactionTable.statusMessage')}</TableCell>
                    <TableCell>{t('transactions.transactionTable.clientType')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row.sourceIP}</TableCell>
                    <TableCell>{row.engineVersion}</TableCell>
                    <TableCell>{row.processingTime}</TableCell>
                    <TableCell>{row.numberOfPages}</TableCell>
                    <TableCell>{row.processedBytes}</TableCell>
                    <TableCell>{row.statusMessage}</TableCell>
                    <TableCell>{row.clientType}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function TransactionTable({ admin }: { admin?: boolean }) {
  const headCells2: TransactionHeadCell[] = admin
    ? [
        ...headCells.slice(0, 1),
        {
          id: 'accountId' as keyof Transaction,
          align: 'left',
          label: 'Account',
        },
        ...headCells.slice(1),
      ]
    : headCells

  const { t } = useTranslation()
  const { accountId } = useParams()
  const { transactionStore } = useRootStore()

  const [transactions, setTransactions] = React.useState<Transaction[]>([])

  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState<keyof Transaction>('creationTime')
  const [page, setPage] = React.useState<number>(0)
  const [totalElements, setTotlaElements] = React.useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10)
  const [filter, setFilter] = React.useState<GetTransactionOptions>({})

  const handleLoadTransactions = React.useCallback(
    debounce((accountId, admin, orderBy, order, rowsPerPage, page, filter) => {
      let requestFilter = {}
      if (admin) {
        requestFilter = { sort: `${orderBy},${order}`, size: rowsPerPage, page: page, ...filter }
      }
      if (accountId) {
        requestFilter = { accountId: accountId, sort: `${orderBy},${order}`, size: rowsPerPage, page: page, ...filter }
      }

      if (Object.keys(requestFilter).length > 0) {
        transactionStore.loadTransactions(requestFilter).then((r: TransactionResponse) => {
          setTransactions(r.content)
          setTotlaElements(r.page.totalElements)
          setPage(r.page.number)
          transactionStore.setLoading(false)
        })
      }
    }, 300), // Adjust the debounce delay (300ms) as needed
    []
  )

  useEffect(() => {
    handleLoadTransactions(accountId, admin, orderBy, order, rowsPerPage, page, filter)
  }, [accountId, admin, orderBy, order, rowsPerPage, page, filter, handleLoadTransactions])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Transaction) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const paginationTheme = createTheme({
    components: {
      MuiTablePagination: {
        defaultProps: {
          labelRowsPerPage: t('transactions.transactionTable.rowsPerPageLabel'),
          labelDisplayedRows: ({ from, to, count }) => {
            return from + '-' + to + t('transactions.transactionTable.labelDisplayedRowsOf') + count
          },
        },
      },
    },
  })

  return (
    <Paper sx={{ minWidth: '95%', minHeight: 'fit-content', mt: 5 }} skeleton={transactionStore.isLoading}>
      <Stack flexDirection={'row'} justifyContent={'space-between'} className='PaperHeader'>
        <h1>{t('transactions.transactionTable.transactions')}</h1>
      </Stack>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-label='Transactions' size={'small'}>
          <EnhancedTableHead
            headCells={headCells2}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={length}
          />
          {filter && <TransactionTableFilter headCells={headCells2} setFilter={setFilter} admin={admin} />}
          <TableBody>
            {!transactionStore.isLoading
              ? transactions.map((row, i) => <EnchancedRow key={'row-' + i} row={row} admin={admin} />)
              : Array.from({ length: rowsPerPage }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={7}>
                      <Skeleton height={32} sx={{ transform: 'none' }} />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ThemeProvider theme={paginationTheme}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ThemeProvider>
    </Paper>
  )
}

export default observer(TransactionTable)
