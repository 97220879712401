export const snippets = [
  {
    type: 'Section',
    name: 'Example snippet',
    enabled: true,
    structureProperties: {
      structureType: 'Sect',
      language: null,
      title: null,
      alternateDescription: null,
      actualText: null,
    },
    startIdentification: {
      enabled: false,
      spots: [],
    },
    endIdentification: {
      enabled: false,
      spots: [],
    },
    positioning: {
      top: false,
      left: false,
      bottom: false,
      right: false,
      spotOffsets: [],
    },
    bbox: {
      x: 13,
      y: 9,
      width: 288,
      height: 190,
    },
    children: [
      {
        type: 'TextField',
        name: 'Retur',
        enabled: true,
        structureProperties: {
          structureType: 'P',
          language: null,
          title: null,
          alternateDescription: null,
          actualText: null,
        },
        startIdentification: {
          enabled: false,
          spots: [],
        },
        endIdentification: {
          enabled: false,
          spots: [],
        },
        positioning: {
          top: false,
          left: false,
          bottom: false,
          right: false,
          spotOffsets: [],
        },
        bbox: {
          x: 11,
          y: 9,
          width: 270,
          height: 75,
        },
      },
      {
        type: 'TextField',
        id: 'cee3192f-aead-4551-8ebf-a2ff2b23bc49',
        name: 'Recipient',
        enabled: true,
        structureProperties: {
          structureType: 'P',
          language: null,
          title: null,
          alternateDescription: null,
          actualText: null,
        },
        startIdentification: {
          enabled: false,
          spots: [],
        },
        endIdentification: {
          enabled: false,
          spots: [],
        },
        positioning: {
          top: false,
          left: false,
          bottom: false,
          right: false,
          spotOffsets: [],
        },
        bbox: {
          x: 11,
          y: 88,
          width: 270,
          height: 90,
        },
      },
    ],
  },
  {
    type: 'Section',
    id: 'a612329b-a726-4138-a375-386650d36938',
    name: 'Example snippet 2',
    enabled: true,
    structureProperties: {
      structureType: 'Sect',
      language: null,
      title: null,
      alternateDescription: null,
      actualText: null,
    },
    startIdentification: {
      enabled: false,
      spots: [],
    },
    endIdentification: {
      enabled: false,
      spots: [],
    },
    positioning: {
      top: false,
      left: false,
      bottom: false,
      right: false,
      spotOffsets: [],
    },
    bbox: {
      x: 13,
      y: 9,
      width: 288,
      height: 190,
    },
    children: [
      {
        type: 'TextField',
        id: '9093da9b-904f-42e9-9a0b-620ad3b87ea0',
        name: 'Retur',
        enabled: true,
        structureProperties: {
          structureType: 'P',
          language: null,
          title: null,
          alternateDescription: null,
          actualText: null,
        },
        startIdentification: {
          enabled: false,
          spots: [],
        },
        endIdentification: {
          enabled: false,
          spots: [],
        },
        positioning: {
          top: false,
          left: false,
          bottom: false,
          right: false,
          spotOffsets: [],
        },
        bbox: {
          x: 11,
          y: 9,
          width: 270,
          height: 75,
        },
      },
      {
        type: 'TextField',
        id: 'cee3192f-aead-4551-8ebf-a2ff2b23bc49',
        name: 'Recipient',
        enabled: true,
        structureProperties: {
          structureType: 'P',
          language: null,
          title: null,
          alternateDescription: null,
          actualText: null,
        },
        startIdentification: {
          enabled: false,
          spots: [],
        },
        endIdentification: {
          enabled: false,
          spots: [],
        },
        positioning: {
          top: false,
          left: false,
          bottom: false,
          right: false,
          spotOffsets: [],
        },
        bbox: {
          x: 11,
          y: 88,
          width: 270,
          height: 90,
        },
      },
    ],
  },
]
