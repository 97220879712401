import React from 'react'
import '../../styles/CanvasView.css'
import { observer } from 'mobx-react'

const UABoundBoxView = ({ scale, selectedProducedData }: { scale: number; selectedProducedData: any }) => {
  const BoundBoxItem = ({ object, itemKey }: { object: any; itemKey?: string }) => {
    return (
      <>
        {object.data?.documentObjects &&
          object.data?.documentObjects.map((child: any, index: number) => (
            <div
              className='ProducedBbox'
              key={itemKey + 'child-' + index}
              style={{
                width: child.width * scale + 'px',
                height: child.height * scale + 'px',
                top: child.y * scale,
                left: child.x * scale,
              }}
            ></div>
          ))}

        {object.children &&
          object.children.map((child: any, index: number) => (
            <BoundBoxItem object={child} itemKey={itemKey + '-' + index} key={itemKey + '-' + index} />
          ))}

        {object.xpPageObjects &&
          object.xpPageObjects.map((child: any, index: number) => (
            <BoundBoxItem object={child} itemKey={itemKey + '-' + index} key={itemKey + '-' + index} />
          ))}

        {object.data?.bbox && (
          <div
            className='ProducedBbox'
            key={itemKey + '-bbox'}
            style={{
              width: object.data.bbox.width * scale + 'px',
              height: object.data.bbox.height * scale + 'px',
              top: object.data.bbox.y * scale,
              left: object.data.bbox.x * scale,
            }}
          ></div>
        )}

        {object.bbox && (
          <div
            className='ProducedBbox'
            key={itemKey + '-bbox'}
            style={{
              width: object.bbox.width * scale + 'px',
              height: object.bbox.height * scale + 'px',
              top: object.bbox.y * scale,
              left: object.bbox.x * scale,
            }}
          ></div>
        )}
      </>
    )
  }

  return (
    <div className='CanvasArea' style={{ zIndex: 20 }}>
      <BoundBoxItem object={selectedProducedData} />
    </div>
  )
}

export default observer(UABoundBoxView)
