import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Outlet } from 'react-router-dom'
import Copyright from '../components/Copyright'
import CustomSnackbar from '../components/CustomSnackbar'

import '../styles/MainLayout.css'

export default function FullScreenLayout() {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[900],
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <CustomSnackbar />

        <Outlet />
        <footer>
          <Container
            maxWidth={false}
            disableGutters
            sx={{
              position: 'absolute',
              bottom: '11px',
              left: '50%',
              transform: 'translate(-50%)',
              width: 'auto',
              opacity: 0.6,
            }}
          >
            <Copyright />
          </Container>
        </footer>
      </Box>
    </Box>
  )
}
