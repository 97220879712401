import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { RootStore } from './RootStore'
import AccountService from '../api/AccountService'
import Account from '../domain/Account'
import eventEmitter from '../components/eventEmitter'
import User from '../domain/User'

export class AccountStore {
  root: RootStore
  accountService: AccountService
  currentAccount: Account | null = null
  accounts: Account[] = []
  users: User[] = []
  isLoading = true

  constructor(root: RootStore) {
    this.root = root
    this.accountService = new AccountService()
    makeAutoObservable(this)
  }

  async loadAccounts() {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const accounts = await this.accountService.list()
      runInAction(() => {
        this.accounts = accounts.map((t) => new Account(t))
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load accounts - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  async loadCurrent(accountId: string) {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const account = await this.accountService.get(accountId)
      runInAction(() => {
        this.currentAccount = new Account(account)
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load current - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  clearCurrent() {
    this.currentAccount = null
  }

  async updateCurrent() {
    if (!this.currentAccount) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update current - No account selected',
        severity: 'error',
      })
      return
    }
    try {
      await this.accountService.put(this.currentAccount)
      // update account in array of accounts
      const accountIndex = this.accounts.findIndex((t) => t.id === this.currentAccount?.id)
      runInAction(() => {
        if (accountIndex != -1 && this.currentAccount) {
          this.accounts[accountIndex] = this.currentAccount
        }
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not update current - ' + error,
        severity: 'error',
      })
    }
  }

  delete = async (account: Account) => {
    try {
      await this.accountService.delete(account.id)
      runInAction(() => {
        this.accounts = this.accounts.filter((t) => t.id !== account.id)
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not delete current - ' + error,
        severity: 'error',
      })
    }
  }

  async create(account: Account): Promise<Account> {
    try {
      const newAccount: Account = await this.accountService.post(account)
      runInAction(() => {
        this.accounts.push(newAccount)
        this.currentAccount = newAccount
      })
      return newAccount
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not create - ' + error,
        severity: 'error',
      })
      return Promise.reject(error)
    }
  }

  async loadUsers() {
    if (!this.currentAccount) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load users - No account selected',
        severity: 'error',
      })
      return
    }
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const users = await this.accountService.listUsers(this.currentAccount.id)
      runInAction(() => {
        this.users = users.map((t) => new User(t))
      })
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not load accountUsers - ' + error,
        severity: 'error',
      })
    }
    runInAction(() => {
      this.isLoading = false
    })
  }

  async assignUser(userId: string) {
    if (!this.currentAccount) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not assign user - No account selected',
        severity: 'error',
      })
      return
    }
    try {
      await this.accountService.assignUser(this.currentAccount.id, userId)
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'could not assign user - ' + error,
        severity: 'error',
      })
    }
  }

  async removeUser(userId: string) {
    if (!this.currentAccount) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not remove user - No account selected',
        severity: 'error',
      })
      return
    }
    try {
      await this.accountService.removeUser(this.currentAccount.id, userId)
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not remove user - ' + error,
        severity: 'error',
      })
    }
  }

  async generateApiKey(): Promise<string> {
    if (!this.currentAccount) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not generate api key - No account selected',
        severity: 'error',
      })
      return Promise.reject()
    }
    try {
      return await this.accountService.generateApiKey(this.currentAccount.id)
    } catch (error) {
      eventEmitter.emit('showSnackbar', {
        message: 'Could not generate api key - ' + error,
        severity: 'error',
      })
      return Promise.reject(error)
    }
  }
}
