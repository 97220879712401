export const ROLES = {
  ADMIN: 'ROLE_xperdocengine.Admin',
  ACCOUNT_MANAGER: 'ROLE_xperdocengine.AccountManager',
  PROJECT_MANAGER: 'ROLE_xperdocengine.ProjectManager',
  TEMPLATE_DESIGNER: 'ROLE_xperdocengine.TemplateDesigner',
} as const

export type Role = keyof typeof ROLES

export const ROLE_HIERARCHY: Record<Role, Role[]> = {
  ADMIN: ['ACCOUNT_MANAGER', 'PROJECT_MANAGER', 'TEMPLATE_DESIGNER'],
  ACCOUNT_MANAGER: ['PROJECT_MANAGER', 'TEMPLATE_DESIGNER'],
  PROJECT_MANAGER: ['TEMPLATE_DESIGNER'],
  TEMPLATE_DESIGNER: [],
}
