import React, { useEffect } from 'react'
import { Skeleton, Stack } from '@mui/material'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useRootStore } from '../providers/RootStoreProvider'
import ProjectManager from '../components/Project/ProjectManager'
import { useTranslation } from 'react-i18next'
import UserManager from '../components/User/UserManager'

const Home = () => {
  const { t } = useTranslation()
  const { accountId } = useParams()
  const { accountStore, projectStore, templateStore } = useRootStore()

  useEffect(() => {
    if (accountId) {
      accountStore.loadCurrent(accountId)

      projectStore.setCurrentAccountId(accountId)
      projectStore.loadProjects()
      projectStore.clearCurrent()
      templateStore.clearCurrent()
    }
  }, [accountId])

  return (
    <Stack direction={'column'} height={'inherit'} alignItems={'left'}>
      <h1>
        {accountStore.currentAccount ? (
          t('home.welcome', { name: accountStore.currentAccount.name })
        ) : (
          <Skeleton width={300} />
        )}
      </h1>
      <Stack direction={'row'}>
        <ProjectManager recentOnly />
      </Stack>
    </Stack>
  )
}

export default observer(Home)
